import { Alert, AlertDescription, AlertTitle } from './ui/Alert';
import PropTypes from 'prop-types';

export const ErrorAlert = ({ errors, title = 'Error' }) => {
  // If no errors, don't render anything
  if (!errors) return null;

  // Handle different types of error inputs
  const errorMessages = typeof errors === 'string'
    ? [errors]
    : typeof errors === 'object' && !Array.isArray(errors)
      ? Object.values(errors)
      : errors;

  return (
    <Alert variant="destructive">
      <AlertTitle>
        {title}
      </AlertTitle>
      {errorMessages.map((error, index) => (
        <AlertDescription key={index}>
          {error}
        </AlertDescription>
      ))}
    </Alert>
  );
};

ErrorAlert.propTypes = {
  errors: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  title: PropTypes.string
};

// Add both named and default exports
export default ErrorAlert;