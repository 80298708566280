import React, { useState } from 'react';
import './ScenarioEditModal.css';

const Button = ({ children, variant = 'default', onClick, className = '' }) => {
  const baseClass = 'modal-button';
  const variantClass = `modal-button-${variant}`;
  return (
    <button 
      className={`${baseClass} ${variantClass} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const ScenarioEditModal = ({ scenario, onSave, onCancel, onStatusChange, user }) => {
  const [editedScenario, setEditedScenario] = useState(scenario);
  const [selectedStatus, setSelectedStatus] = useState(scenario.status || 'Draft');
  
  const handleInputChange = (field, value) => {
    setEditedScenario(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleStatusChange = async () => {
    const now = new Date().toISOString();
    const statusChangeComment = `Status changed to ${selectedStatus} by ${user.email} on ${now}`;
    
    const updatedScenario = {
      ...editedScenario,
      status: selectedStatus,
      comments: editedScenario.comments
        ? `${editedScenario.comments}\n${statusChangeComment}`
        : statusChangeComment
    };
    
    onStatusChange(updatedScenario);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>Scenario Details</h2>
          <div className="header-buttons">
            <button 
              className="btn btn-secondary btn-sm"
              onClick={onCancel}
            >
              Cancel Edit
            </button>
            <select 
              className="status-select"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="Draft">Draft</option>
              <option value="In Review">In Review</option>
              <option value="Ready">Ready</option>
              <option value="Published">Published</option>
            </select>
            <button 
              className="btn btn-primary btn-sm"
              onClick={handleStatusChange}
            >
              Change Status
            </button>
            <button 
              className="btn btn-primary btn-sm"
              onClick={() => onSave(editedScenario)}
            >
              Save Changes
            </button>
          </div>
        </div>

        <div className="form-container">
          <div className="form-row">
            <div className="form-group half">
              <label>Scenario ID</label>
              <input
                type="text"
                value={editedScenario.scenarioid || ''}
                onChange={(e) => handleInputChange('scenarioid', e.target.value)}
              />
            </div>
            <div className="form-group half">
              <label>Order</label>
              <input
                type="text"
                value={editedScenario.order || ''}
                onChange={(e) => handleInputChange('order', e.target.value)}
              />
            </div>
          </div>

          <div className="form-group">
            <label>Category</label>
            <input
              type="text"
              value={editedScenario.category || ''}
              onChange={(e) => handleInputChange('category', e.target.value)}
            />
          </div>

          <div className="form-group">
            <label>Diagnosis Category</label>
            <input
              type="text"
              value={editedScenario.diagnosiscategory || ''}
              onChange={(e) => handleInputChange('diagnosiscategory', e.target.value)}
            />
          </div>

          <div className="form-group">
            <label>Title</label>
            <input
              type="text"
              value={editedScenario.title || ''}
              onChange={(e) => handleInputChange('title', e.target.value)}
            />
          </div>

          <div className="form-group">
            <label>Tags</label>
            <input
              type="text"
              value={editedScenario.tags || ''}
              onChange={(e) => handleInputChange('tags', e.target.value)}
            />
          </div>

          <div className="form-group">
            <label>Patient Report</label>
            <textarea
              value={editedScenario.patientreport || ''}
              onChange={(e) => handleInputChange('patientreport', e.target.value)}
              rows={4}
            />
          </div>

          <div className="form-group">
            <label>Question</label>
            <textarea
              value={editedScenario.question || ''}
              onChange={(e) => handleInputChange('question', e.target.value)}
              rows={3}
            />
          </div>

          {[1, 2, 3, 4].map((num) => (
            <div key={`option${num}`} className="form-group">
              <label>Option {num}</label>
              <textarea
                value={editedScenario[`option${num}`] || ''}
                onChange={(e) => handleInputChange(`option${num}`, e.target.value)}
                rows={2}
              />
            </div>
          ))}

          <div className="form-group">
            <label>Answer</label>
            <input
              type="text"
              value={editedScenario.answer || ''}
              onChange={(e) => handleInputChange('answer', e.target.value)}
            />
          </div>

          {[1, 2, 3, 4].map((num) => (
            <div key={`explanation${num}`} className="form-group">
              <label>Explanation {num}</label>
              <textarea
                value={editedScenario[`explanation${num}`] || ''}
                onChange={(e) => handleInputChange(`explanation${num}`, e.target.value)}
                rows={3}
              />
            </div>
          ))}

          <div className="form-group">
            <label>Comments</label>
            <textarea
              value={editedScenario.comments || ''}
              readOnly
              rows={3}
              className="readonly"
            />
          </div>

          <div className="form-row">
            <div className="form-group half">
              <label>Date Added</label>
              <input
                type="text"
                value={editedScenario.dateadded || ''}
                readOnly
                className="readonly"
              />
            </div>
            <div className="form-group half">
              <label>Last Modified</label>
              <input
                type="text"
                value={editedScenario.lastmodified || ''}
                readOnly
                className="readonly"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScenarioEditModal;