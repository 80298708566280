import React from 'react';
import { Settings } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const AdminNav = () => {
  const navigate = useNavigate();
  
  return (
    <button 
      onClick={() => navigate('/admin')}
      className="fixed top-4 right-4 p-2 rounded-full hover:bg-gray-100 bg-white shadow-sm"
      aria-label="Admin Settings"
    >
      <Settings className="w-6 h-6 text-gray-600" />
    </button>
  );
};

export default AdminNav;