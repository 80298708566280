import React, { useState, useRef } from 'react';

const CSVImport = ({ onImport, supabase, user }) => {
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);
    const [importing, setImporting] = useState(false);
    const inputRef = useRef(null);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file && !file.name.endsWith('.csv')) {
      setError('Please upload a CSV file');
      return;
    }
    setFile(file);
    setError(null);
  };

  const handleImport = async () => {
    if (!file) return;
    setImporting(true);
    setError(null);

    try {
      const text = await file.text();
      const rows = text.split('\n')
        .map(row => row.trim())
        .filter(row => row.length > 0);

      const headers = rows[0].split(',').map(h => h.trim());
      const scenarios = rows.slice(1).map(row => {
        const values = row.split(',');
        const scenario = {};
        
        headers.forEach((header, i) => {
          const value = values[i]?.trim() || '';
          if (header === 'scenarioid' || header === 'order') {
            scenario[header] = parseInt(value) || null;
          } else {
            scenario[header] = value;
          }
        });
        
        return scenario;
      });

      const { data, error } = await supabase
        .from('scenarios')
        .insert(scenarios)
        .select();

      if (error) throw error;
      onImport(data);
      setFile(null);
    } catch (error) {
      setError(error.message);
    } finally {
      setImporting(false);
    }
  };
  
    return (
      <div className="generator-buttons">
        <button 
          className="btn btn-primary"
          onClick={() => inputRef.current?.click()}
        >
          Choose CSV File
        </button>
        <input
          ref={inputRef}
          type="file"
          accept=".csv"
          onChange={handleFileUpload}
          style={{ display: 'none' }}
        />
        {file && (
          <button 
            onClick={handleImport}
            disabled={importing}
            className="btn btn-success"
          >
            {importing ? 'Importing...' : 'Import CSV'}
          </button>
        )}
        {error && <div className="error-message">{error}</div>}
      </div>
    );
  };

  export default CSVImport;