export const trackEvent = (eventName, properties = {}) => {
  try {
    console.log(`Event tracked: ${eventName}`, properties);
    // Add your analytics implementation here
  } catch (error) {
    console.error('Error tracking event:', error);
  }
};

export const logError = (message, error) => {
  console.error(message, error);
  trackEvent('error', { message, error: error.message });
};

export const analytics = {
  trackEvent,
  logError
};