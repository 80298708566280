// Create a new file at src/components/ui/Modal.js:
import React from 'react';

const Modal = ({ isOpen, onClose, title, description, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h3>{title}</h3>
        </div>
        <div className="modal-body">
          <p>{description}</p>
        </div>
        <div className="modal-footer">
          <button 
            onClick={onClose} 
            className="btn btn-secondary"
          >
            No
          </button>
          <button 
            onClick={onConfirm} 
            className="btn btn-primary"
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;