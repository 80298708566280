import React, { useState, useEffect, useRef } from 'react';
import Modal from '../ui/Modal';
import { Link } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { useAuth } from '../../contexts/auth/AuthContext';
import { useRoleBasedAccess } from '../../contexts/auth/roleUtils';
import './ValidationView.css';
import DevelopmentModeBanner from '../../components/DevelopmentModeBanner';

// TestImportButton Component
const TestImportButton = () => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  const handleImport = async () => {
    setLoading(true);
    setError(null);
    setResult(null);

    try {
      const response = await fetch('https://gfmtnfxmtlkzzhaebrvk.supabase.co/functions/v1/local-import', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJ0ZmVleXp4YnZkemF6ZWFia2d0Iiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTcxNTc2NDUxOCwiZXhwIjoyMDMxMzQwNTE4fQ.FeIv7kRz_0CR8u8hjo4beu2W3-QMhQKpl-M75HckoZI'
        },
        body: JSON.stringify({
          timestamp: new Date().toISOString()
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Import test response:', data);
      setResult(data);
    } catch (err) {
      console.error('Import test error:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: 'inline-block', marginLeft: '1rem' }}>
      <button 
        onClick={handleImport}
        disabled={loading}
        className="btn btn-primary"
      >
        {loading ? 'Testing Connection...' : 'Test Import Connection'}
      </button>

      {error && (
        <div style={{ 
          color: '#e53e3e',
          backgroundColor: '#fff5f5',
          border: '1px solid #feb2b2',
          padding: '0.5rem 1rem',
          borderRadius: '4px',
          marginTop: '0.5rem',
          fontSize: '0.875rem'
        }}>
          Error: {error}
        </div>
      )}

      {result && (
        <div style={{
          color: '#2f855a',
          backgroundColor: '#f0fff4',
          border: '1px solid #9ae6b4',
          padding: '0.5rem 1rem',
          borderRadius: '4px',
          marginTop: '0.5rem',
          fontSize: '0.875rem'
        }}>
          Connection Successful!
          <pre style={{ marginTop: '0.5rem', whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
            {JSON.stringify(result, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
};

function ValidationView() {
  const [isValidating, setIsValidating] = useState(false);
  const [validationResults, setValidationResults] = useState([]);
  const [showFirstConfirm, setShowFirstConfirm] = useState(false);
  const [showSecondConfirm, setShowSecondConfirm] = useState(false);
  const [error, setError] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [statusOptions, setStatusOptions] = useState([]);
  const [canScroll, setCanScroll] = useState({
    left: false,
    right: false,
    up: false,
    down: false
  });
  
  const tableScrollRef = useRef(null);
  const { user } = useAuth();
  const { canImportCSV } = useRoleBasedAccess();

  const columns = [
    { name: 'status', label: 'Status', width: '150px' },
    { name: 'ignore_action', label: 'Ignore', width: '100px' },
    { name: 'scenarioid', label: 'Scenario ID', width: '100px' },
    { name: 'order', label: 'Order', width: '70px' },
    { name: 'validation_errors', label: 'Validation Errors', width: '300px' },
    { name: 'category', label: 'Category', width: '150px' },
    { name: 'diagnosiscategory', label: 'Diagnosis Category', width: '150px' },
    { name: 'title', label: 'Title', width: '200px' },
    { name: 'patientreport', label: 'Patient Report', width: '300px' },
    { name: 'question', label: 'Question', width: '300px' },
    { name: 'option1', label: 'Option 1', width: '200px' },
    { name: 'option2', label: 'Option 2', width: '200px' },
    { name: 'option3', label: 'Option 3', width: '200px' },
    { name: 'option4', label: 'Option 4', width: '200px' },
    { name: 'answer', label: 'Answer', width: '70px' },
    { name: 'explanation1', label: 'Explanation 1', width: '400px' },
    { name: 'explanation2', label: 'Explanation 2', width: '400px' },
    { name: 'explanation3', label: 'Explanation 3', width: '400px' },
    { name: 'explanation4', label: 'Explanation 4', width: '400px' },
    { name: 'comments', label: 'Comments', width: '300px' },
    { name: 'image', label: 'Image', width: '150px' }
  ];

  useEffect(() => {
    fetchStatusOptions();
  }, []);

  useEffect(() => {
    const container = tableScrollRef.current;
    if (!container) return;

    const checkScrollability = () => {
      setCanScroll({
        left: container.scrollLeft > 0,
        right: container.scrollLeft < (container.scrollWidth - container.clientWidth),
        up: container.scrollTop > 0,
        down: container.scrollTop < (container.scrollHeight - container.clientHeight)
      });
    };

    container.addEventListener('scroll', checkScrollability);
    window.addEventListener('resize', checkScrollability);
    checkScrollability();

    return () => {
      container.removeEventListener('scroll', checkScrollability);
      window.removeEventListener('resize', checkScrollability);
    };
  }, [validationResults]);

  const fetchStatusOptions = async () => {
    try {
      const { data, error } = await supabase
        .from('scenarios')
        .select('status')
        .not('status', 'is', null);
      
      if (error) throw error;

      const uniqueStatuses = [...new Set(data.map(item => item.status))];
      setStatusOptions(uniqueStatuses.sort());
    } catch (error) {
      console.error('Error fetching status options:', error);
      setError('Failed to fetch status options');
    }
  };

  const cleanExplanationStart = (text) => {
    if (!text) return '';
    return text.replace(/^[\s.,;:\-()]*/, '').trim();
  };

  const handleIgnoreValidation = async (scenarioId, order) => {
    try {
      const { error: ignoreError } = await supabase
        .from('ignored_validations')
        .insert({
          scenario_id: scenarioId,
          question_order: order,
          ignored_by: user?.email
        });

      if (ignoreError) throw ignoreError;

      setValidationResults(prev => 
        prev.filter(result => 
          !(result.scenarioid === scenarioId && result.order === order)
        )
      );
    } catch (error) {
      console.error('Error ignoring validation:', error);
      setError('Failed to ignore validation');
    }
  };

  const handleLocalImport = () => {
    setShowFirstConfirm(true);
  };

  const handleConfirmed = async () => {
    try {
      const { data, error } = await supabase.functions.invoke('local-import', {
        body: { 
          // your data here
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${supabase.auth.getSession()?.access_token}`
        }
      });

      if (error) throw error;
      
      // Handle successful response
      console.log('Import successful:', data);
      setStatusMessage('Import completed successfully');
      
    } catch (error) {
      console.error('Error during local import:', error);
      setError('Failed to import: ' + error.message);
    } finally {
      setShowSecondConfirm(false);
    }
  };

  const validateScenarios = async () => {
    setIsValidating(true);
    setError(null);
    setValidationResults([]);
  
    try {
      const { data: ignoredValidations, error: ignoredError } = await supabase
        .from('ignored_validations')
        .select('scenario_id, question_order');
  
      if (ignoredError) throw ignoredError;
  
      const ignoredSet = new Set(
        ignoredValidations.map(iv => `${iv.scenario_id}-${iv.question_order}`)
      );
  
      const { data: scenarios, error } = await supabase
        .from('scenarios')
        .select('*')
        .eq('status', 'Reviewed')
        .order('scenarioid', { ascending: true })
        .order('order', { ascending: true });
  
      if (error) throw error;
  
      const scenarioGroups = scenarios.reduce((acc, scenario) => {
        if (!acc[scenario.scenarioid]) {
          acc[scenario.scenarioid] = [];
        }
        acc[scenario.scenarioid].push(scenario);
        return acc;
      }, {});

      const validationErrors = [];

      Object.entries(scenarioGroups).forEach(([scenarioId, questions]) => {
        const sortedQuestions = questions.sort((a, b) => a.order - b.order);
        
        if (sortedQuestions.length < 4) {
          sortedQuestions.forEach(question => {
            if (!ignoredSet.has(`${question.scenarioid}-${question.order}`)) {
              validationErrors.push({
                ...question,
                validation_errors: ['Scenario has less than 4 questions']
              });
            }
          });
        }

        if (!sortedQuestions[0]?.image && !ignoredSet.has(`${sortedQuestions[0]?.scenarioid}-${sortedQuestions[0]?.order}`)) {
          validationErrors.push({
            ...sortedQuestions[0],
            validation_errors: ['First question missing image']
          });
        }

        sortedQuestions.forEach(question => {
          if (ignoredSet.has(`${question.scenarioid}-${question.order}`)) {
            return;
          }

          const questionErrors = [];

          const baseRequiredFields = [
            'scenarioid', 'order', 'category', 'diagnosiscategory', 'title',
            'patientreport', 'question', 'option1', 'option2', 'option3',
            'option4', 'answer', 'explanation1', 'explanation2', 'explanation3',
            'explanation4'
          ];

          const requiredFields = parseInt(question.scenarioid) > 486 
            ? [...baseRequiredFields, 'comments']
            : baseRequiredFields;
      
          const missingFields = requiredFields.filter(field => !question[field]);
          if (missingFields.length > 0) {
            questionErrors.push(`Missing required fields: ${missingFields.join(', ')}`);
          }

          const validPrefixes = ['Correct', 'Somewhat Correct', 'Somewhat Incorrect', 'Incorrect'];
          ['explanation1', 'explanation2', 'explanation3', 'explanation4'].forEach((field, index) => {
            const explanation = question[field];
            const isCorrectAnswer = question.answer === String(index + 1);

            const cleanedExplanation = cleanExplanationStart(explanation);
            
            if (!validPrefixes.some(prefix => 
              cleanedExplanation.toLowerCase().startsWith(prefix.toLowerCase())
            )) {
              questionErrors.push(`${field} has invalid prefix`);
            }

            if (isCorrectAnswer && !cleanedExplanation.toLowerCase().startsWith('correct')) {
              questionErrors.push(`${field} should start with "Correct" for the correct answer`);
            }

            if (!isCorrectAnswer && parseInt(question.scenarioid) > 486 && explanation && 
                !explanation.toLowerCase().includes(String(question.answer).toLowerCase())) {
              questionErrors.push(`${field} should reference the correct answer`);
            }
          });

          if (questionErrors.length > 0) {
            validationErrors.push({
              ...question,
              validation_errors: questionErrors
            });
          }
        });
      });

      setValidationResults(validationErrors);

    } catch (error) {
      console.error('Validation error:', error);
      setError('Failed to complete validation: ' + error.message);
    } finally {
      setIsValidating(false);
    }
  };

  const handleInputChange = async (e, id, field) => {
    const { value } = e.target;
    
    try {
      const { error } = await supabase
        .from('scenarios')
        .update({ [field]: value })
        .eq('id', id);

      if (error) throw error;

      setValidationResults(prev =>
        prev.map(result =>
          result.id === id ? { ...result, [field]: value } : result
        )
      );
    } catch (error) {
      console.error('Error updating field:', error);
      setError(`Failed to update field: ${error.message}`);
    }
  };

  const handleScroll = (direction) => {
    const container = tableScrollRef.current;
    if (!container) return;
  
    const scrollAmount = 200;
    const scrollOptions = { behavior: 'smooth' };
  
    switch (direction) {
      case 'left':
        container.scrollBy({ left: -scrollAmount, ...scrollOptions });
        break;
      case 'right':
        container.scrollBy({ left: scrollAmount, ...scrollOptions });
        break;
      case 'up':
        container.scrollBy({ top: -scrollAmount, ...scrollOptions });
        break;
      case 'down':
        container.scrollBy({ top: scrollAmount, ...scrollOptions });
        break;
      default:
        break;
    }
  };

  const renderCell = (column, value, row) => {
    if (column.name === 'status') {
      return (
        <select
          value={value || ''}
          onChange={(e) => handleInputChange(e, row.id, 'status')}
          className="form-select"
        >
          <option value="">Select Status</option>
          {statusOptions.map(status => (
            <option key={status} value={status}>
              {status}
            </option>
          ))}
        </select>
      );
    }

    if (column.name === 'ignore_action') {
      return (
        <button
          onClick={() => handleIgnoreValidation(row.scenarioid, row.order)}
          className="btn btn-warning btn-sm"
        >
          Ignore
        </button>
      );
    }

    if (column.name === 'validation_errors') {
      return (
        <div className="validation-errors">
          {Array.isArray(value) && value.map((error, index) => (
            <div key={index} className="validation-error">{error}</div>
          ))}
        </div>
      );
    }

    return value || '';
  };

  return (
    <>
      <DevelopmentModeBanner />
    <div className="validation-view">
      <div className="header-container">
        <h1>Scenario Validation</h1>
        <div className="navigation-controls">
          <Link to="/home" className="btn btn-secondary">Home</Link>
          <Link to="/datasheet" className="btn btn-secondary">Datasheet</Link>
        </div>
      </div>

      <div className="validation-controls">
        <button
          onClick={validateScenarios}
          disabled={isValidating}
          className="btn btn-primary"
        >
          {isValidating ? 'Validating...' : 'Validate Scenarios'}
        </button>
        {canImportCSV && (
          <>
            <button
              onClick={handleLocalImport}
              className="btn btn-primary"
              style={{ marginLeft: '1rem' }}
            >
              Run Local Import
            </button>
            <TestImportButton />
          </>
        )}
      </div>

      {error && (
        <div className="error-message">
          {error}
        </div>
      )}

      {statusMessage && (
        <div className="status-message">
          {statusMessage}
        </div>
      )}

      <div className="validation-summary">
        {validationResults.length > 0 && (
          <div className="summary-text">
            Found {validationResults.length} validation errors in reviewed questions
          </div>
        )}
      </div>

      <div className="table-container">
        {validationResults.length > 0 && (
          <div className="table-scroll-container" ref={tableScrollRef}>
            <table className="scenarios-table">
              <thead>
                <tr>
                  {columns.map(column => (
                    <th 
                      key={column.name}
                      style={{ width: column.width, minWidth: column.width }}
                    >
                      {column.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {validationResults.map(result => (
                  <tr key={`${result.scenarioid}-${result.order}`}>
                    {columns.map(column => (
                      <td key={`${result.scenarioid}-${result.order}-${column.name}`}>
                        {renderCell(column, result[column.name], result)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {validationResults.length > 0 && (
        <div className="scroll-controls">
          <div className="scroll-buttons">
            <button
              className="scroll-button"
              onClick={() => handleScroll('up')}
              disabled={!canScroll.up}
              aria-label="Scroll up"
            >
              ↑
            </button>
            <button
              className="scroll-button"
              onClick={() => handleScroll('down')}
              disabled={!canScroll.down}
              aria-label="Scroll down"
            >
              ↓
            </button>
          </div>
          <div className="horizontal-scroll-buttons">
            <button
              className="scroll-button"
              onClick={() => handleScroll('left')}
              disabled={!canScroll.left}
              aria-label="Scroll left"
            >
              ←
            </button>
            <button
              className="scroll-button"
              onClick={() => handleScroll('right')}
              disabled={!canScroll.right}
              aria-label="Scroll right"
            >
              →
            </button>
          </div>
        </div>
      )}

      <Modal
        isOpen={showFirstConfirm}
        onClose={() => setShowFirstConfirm(false)}
        title="First Confirmation"
        description="Please confirm you wish to proceed"
        onConfirm={() => {
          setShowFirstConfirm(false);
          setShowSecondConfirm(true);
        }}
      />

      <Modal
        isOpen={showSecondConfirm}
        onClose={() => setShowSecondConfirm(false)}
        title="Final Confirmation"
        description="Are you sure you want to proceed? This cannot be undone"
        onConfirm={handleConfirmed}
      />
    </div>
      </>
  );
}

export default ValidationView;