import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { useAuth } from '../../contexts/auth/AuthContext';
import { useRoleBasedAccess } from '../../contexts/auth/roleUtils';
import CSVImport from '../CSVImport/CSVImportForm';
import EnhancedClaudeGenerator from '../EnhancedClaudeGenerator/EnhancedClaudeGenerator';
import ScenarioEditModal from '../ScenarioEditModal';
import './DatasheetView.css';
import AdminNav from '../admin/AdminNav';
import AdminPage from '../admin/AdminNav';
// Fixed options
const FIXED_USER_ROLES = [
  'All Roles',
  'EMT',
  'AEMT',
  'Paramedic',
  'Medical Student'
];

const FIXED_COMPLEXITY_LEVELS = [
  'All Complexity Levels',
  'Easy',
  'Medium',
  'Hard',
  'Complex'
];

const FIXED_DIFFICULTY_LEVELS = [
  'All Difficulties',
  ...[...Array(10)].map((_, i) => (i + 1).toString())
];

function DatasheetView() {
  const navigate = useNavigate();
  const [scenarios, setScenarios] = useState([]);
  const [error, setError] = useState(null);
  const [totalScenarios, setTotalScenarios] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [pageInput, setPageInput] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [scenarioToEdit, setScenarioToEdit] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [scenarioToDelete, setScenarioToDelete] = useState(null);

  // Filter states
  const [selectedStatus, setSelectedStatus] = useState('All Statuses');
  const [selectedCategory, setSelectedCategory] = useState('All Categories');
  const [selectedDiagnosisCategory, setSelectedDiagnosisCategory] = useState('All Diagnosis Categories');
  const [selectedUserRole, setSelectedUserRole] = useState('All Roles');
  const [selectedComplexity, setSelectedComplexity] = useState('All Complexity Levels');
  const [selectedDifficulty, setSelectedDifficulty] = useState('All Difficulties');

  // Filter options states
  const [statusOptions, setStatusOptions] = useState(['All Statuses']);
  const [categoryOptions, setCategoryOptions] = useState(['All Categories']);
  const [diagnosisCategoryOptions, setDiagnosisCategoryOptions] = useState(['All Diagnosis Categories']);
  const [userRoleOptions] = useState(FIXED_USER_ROLES);
  const [complexityOptions] = useState(FIXED_COMPLEXITY_LEVELS);
  const [difficultyOptions] = useState(FIXED_DIFFICULTY_LEVELS);

  const tableScrollRef = React.useRef(null);
  const { user, logout } = useAuth();
  const {
    canAccessDatasheet
  } = useRoleBasedAccess();

  const SCENARIOS_PER_PAGE = 25;

  const columns = [
    { name: 'edit', label: 'Edit', width: '80px' },
    { name: 'delete', label: 'Delete', width: '80px' },
    { name: 'status', label: 'Status', width: '150px' },
    { name: 'scenarioid', label: 'Scenario ID', width: '100px' },
    { name: 'order', label: 'Order', width: '70px' },
    { name: 'category', label: 'Category', width: '150px' },
    { name: 'diagnosiscategory', label: 'Diagnosis Category', width: '150px' },
    { name: 'title', label: 'Title', width: '200px' },
    { name: 'patientreport', label: 'Patient Report', width: '300px' },
    { name: 'question', label: 'Question', width: '300px' },
    { name: 'option1', label: 'Option 1', width: '200px' },
    { name: 'option2', label: 'Option 2', width: '200px' },
    { name: 'option3', label: 'Option 3', width: '200px' },
    { name: 'option4', label: 'Option 4', width: '200px' },
    { name: 'answer', label: 'Answer', width: '70px' },
    { name: 'explanation1', label: 'Explanation 1', width: '400px' },
    { name: 'explanation2', label: 'Explanation 2', width: '400px' },
    { name: 'explanation3', label: 'Explanation 3', width: '400px' },
    { name: 'explanation4', label: 'Explanation 4', width: '400px' },
    { name: 'image', label: 'Image', width: '150px' },
    { name: 'userrole', label: 'User Role', width: '150px' },
    { name: 'questiondifficulty', label: 'Difficulty', width: '100px' },
    { name: 'complexity', label: 'Complexity', width: '150px' }
  ];

  const fetchUniqueValues = async (columnName, setOptions, defaultOption) => {
    try {
      const { data, error } = await supabase
        .from('scenarios')
        .select(columnName)
        .not(columnName, 'is', null);

      if (error) throw error;

      const uniqueValues = [...new Set(data
        .map(item => item[columnName])
        .filter(value => value != null && value.toString().trim() !== '')
      )].sort((a, b) => {
        const numA = Number(a);
        const numB = Number(b);
        if (!isNaN(numA) && !isNaN(numB)) {
          return numA - numB;
        }
        return a.toString().localeCompare(b.toString());
      });

      setOptions([defaultOption, ...uniqueValues]);
    } catch (error) {
      console.error(`Error fetching ${columnName} options:`, error);
      setError(`Failed to fetch ${columnName} options: ${error.message}`);
    }
  };

  const fetchFilterOptions = async () => {
    setIsLoading(true);
    try {
      await Promise.all([
        fetchUniqueValues('status', setStatusOptions, 'All Statuses'),
        fetchUniqueValues('category', setCategoryOptions, 'All Categories'),
        fetchUniqueValues('diagnosiscategory', setDiagnosisCategoryOptions, 'All Diagnosis Categories')
      ]);
    } catch (error) {
      console.error('Error fetching filter options:', error);
      setError('Failed to fetch filter options. Please refresh the page.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchScenarios = useCallback(async () => {
    setIsLoading(true);
    try {
      let query = supabase
        .from('scenarios')
        .select('*');
      
      if (selectedStatus !== 'All Statuses') {
        query = query.eq('status', selectedStatus);
      }
      if (selectedCategory !== 'All Categories') {
        query = query.eq('category', selectedCategory);
      }
      if (selectedDiagnosisCategory !== 'All Diagnosis Categories') {
        query = query.eq('diagnosiscategory', selectedDiagnosisCategory);
      }
      if (selectedUserRole !== 'All Roles') {
        query = query.eq('userrole', selectedUserRole);
      }
      if (selectedComplexity !== 'All Complexity Levels') {
        query = query.eq('complexity', selectedComplexity);
      }
      if (selectedDifficulty !== 'All Difficulties') {
        query = query.eq('questiondifficulty', Number(selectedDifficulty));
      }

      query = query
        .order('scenarioid', { ascending: false })
        .order('order', { ascending: true })
        .range((currentPage - 1) * SCENARIOS_PER_PAGE, currentPage * SCENARIOS_PER_PAGE - 1);

      const { data, error } = await query;
      if (error) throw error;

      setScenarios(data);
    } catch (error) {
      console.error('Error fetching scenarios:', error);
      setError(`Failed to fetch scenarios: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, selectedStatus, selectedCategory, selectedDiagnosisCategory, 
      selectedUserRole, selectedComplexity, selectedDifficulty]);
      const fetchTotalCount = useCallback(async () => {
        try {
          let query = supabase
            .from('scenarios')
            .select('*', { count: 'exact', head: true });
    
          if (selectedStatus !== 'All Statuses') {
            query = query.eq('status', selectedStatus);
          }
          if (selectedCategory !== 'All Categories') {
            query = query.eq('category', selectedCategory);
          }
          if (selectedDiagnosisCategory !== 'All Diagnosis Categories') {
            query = query.eq('diagnosiscategory', selectedDiagnosisCategory);
          }
          if (selectedUserRole !== 'All Roles') {
            query = query.eq('userrole', selectedUserRole);
          }
          if (selectedComplexity !== 'All Complexity Levels') {
            query = query.eq('complexity', selectedComplexity);
          }
          if (selectedDifficulty !== 'All Difficulties') {
            query = query.eq('questiondifficulty', Number(selectedDifficulty));
          }
    
          const { count, error } = await query;
          if (error) throw error;
          setTotalScenarios(count);
        } catch (error) {
          console.error('Error fetching total count:', error);
          setError(`Failed to fetch total count: ${error.message}`);
        }
      }, [selectedStatus, selectedCategory, selectedDiagnosisCategory, 
          selectedUserRole, selectedComplexity, selectedDifficulty]);
    
      function debounce(func, wait) {
        let timeout;
        return function executedFunction(...args) {
          const later = () => {
            clearTimeout(timeout);
            func(...args);
          };
          clearTimeout(timeout);
          timeout = setTimeout(later, wait);
        };
      }
    
      const debouncedFetch = useCallback(
        debounce(async () => {
          await fetchScenarios();
          await fetchTotalCount();
        }, 300),
        [fetchScenarios, fetchTotalCount]
      );
    
      const handleEditClick = (scenario) => {
        setScenarioToEdit(scenario);
        setShowEditModal(true);
      };
    
      const handleEditSave = async (updatedScenario) => {
        try {
          const { error } = await supabase
            .from('scenarios')
            .update(updatedScenario)
            .eq('id', updatedScenario.id);
    
          if (error) throw error;
    
          setScenarios(prev =>
            prev.map(scenario =>
              scenario.id === updatedScenario.id ? updatedScenario : scenario
            )
          );
    
          await logAudit('update_scenario', `Updated scenario ${updatedScenario.scenarioid}-${updatedScenario.order}`);
          setShowEditModal(false);
          setScenarioToEdit(null);
        } catch (error) {
          console.error('Error updating scenario:', error);
          setError(`Failed to update scenario: ${error.message}`);
        }
      };
    
      const handleStatusChange = async (updatedScenario) => {
        try {
          const { error } = await supabase
            .from('scenarios')
            .update({ 
              status: updatedScenario.status,
              lastmodifiedby: user?.email,
              lastmodifieddate: new Date().toISOString()
            })
            .eq('id', updatedScenario.id);
    
          if (error) throw error;
    
          setScenarios(prev =>
            prev.map(scenario =>
              scenario.id === updatedScenario.id ? {
                ...scenario,
                status: updatedScenario.status,
                lastmodifiedby: user?.email,
                lastmodifieddate: new Date().toISOString()
              } : scenario
            )
          );
    
          await logAudit('update_status', 
            `Updated status for scenario ${updatedScenario.scenarioid}-${updatedScenario.order} to ${updatedScenario.status}`
          );
        } catch (error) {
          console.error('Error updating scenario status:', error);
          setError(`Failed to update scenario status: ${error.message}`);
        }
      };
    
      const handleFilterChange = async (value, type) => {
        try {
          switch (type) {
            case 'status':
              setSelectedStatus(value);
              break;
            case 'category':
              setSelectedCategory(value);
              break;
            case 'diagnosis':
              setSelectedDiagnosisCategory(value);
              break;
            case 'role':
              setSelectedUserRole(value);
              break;
            case 'complexity':
              setSelectedComplexity(value);
              break;
            case 'difficulty':
              setSelectedDifficulty(value);
              break;
            default:
              return;
          }
    
          setCurrentPage(1);
          debouncedFetch();
        } catch (error) {
          console.error('Error applying filter:', error);
          setError('Failed to apply filter');
        }
      };
    
      const clearFilters = useCallback(() => {
        setSelectedStatus('All Statuses');
        setSelectedCategory('All Categories');
        setSelectedDiagnosisCategory('All Diagnosis Categories');
        setSelectedUserRole('All Roles');
        setSelectedComplexity('All Complexity Levels');
        setSelectedDifficulty('All Difficulties');
        setCurrentPage(1);
        setError(null);
        debouncedFetch();
      }, [debouncedFetch]);
    
      const handleDeleteScenario = async () => {
        if (!scenarioToDelete) return;
        
        try {
          setIsLoading(true);
          
          const { error: deleteError } = await supabase
            .rpc('delete_scenario', {
              p_scenarioid: scenarioToDelete.scenarioid,
              p_order: scenarioToDelete.order
            });
      
          if (deleteError) throw deleteError;
      
          await logAudit('delete_question', `Deleted question ${scenarioToDelete.scenarioid}-${scenarioToDelete.order}`);
          
          setScenarios(prev => prev.filter(s => 
            !(s.scenarioid === scenarioToDelete.scenarioid && s.order === scenarioToDelete.order)
          ));
          
          setScenarioToDelete(null);
          setShowDeleteConfirmation(false);
          
          await fetchTotalCount();
          await fetchScenarios();
          
        } catch (error) {
          console.error('Error deleting question:', error);
          setError('Failed to delete question: ' + error.message);
        } finally {
          setIsLoading(false);
        }
      };
    
      const handleExportCSV = async () => {
        try {
          const csvContent = convertToCSV(scenarios);
          downloadCSV(csvContent, `scenarios_export_${new Date().toISOString().split('T')[0]}.csv`);
          await logAudit('export_csv', 'Exported scenarios to CSV');
        } catch (error) {
          console.error('Error exporting CSV:', error);
          setError('Failed to export CSV');
        }
      };
    
      const convertToCSV = (data) => {
        const headers = columns.map(col => col.name).join(',');
        const rows = data.map(row => 
          columns.map(col => `"${(row[col.name] || '').toString().replace(/"/g, '""')}"`).join(',')
        );
        return [headers, ...rows].join('\n');
      };
    
      const downloadCSV = (csvContent, filename) => {
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      };
    
      const handlePageChange = (newPage) => {
        const totalPages = Math.ceil(totalScenarios / SCENARIOS_PER_PAGE);
        if (newPage >= 1 && newPage <= totalPages && !isLoading) {
          setCurrentPage(newPage);
          setPageInput('');
        }
      };
    
      const handlePageInputChange = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, '');
        setPageInput(value);
      };
    
      const logAudit = async (action, details) => {
        try {
          const { error } = await supabase
            .from('audit_log')
            .insert({ user_email: user?.email, action, details });
          if (error) throw error;
        } catch (error) {
          console.error('Error logging audit:', error.message);
        }
      };
    
      useEffect(() => {
        if (!canAccessDatasheet) {
          navigate('/home');
        }
      }, [canAccessDatasheet, navigate]);
    
      useEffect(() => {
        fetchScenarios();
        fetchTotalCount();
      }, [fetchScenarios, fetchTotalCount]);
    
      useEffect(() => {
        fetchFilterOptions();
      }, []);
    
      if (!canAccessDatasheet) {
        return null;
      }
    
      const totalPages = Math.ceil(totalScenarios / SCENARIOS_PER_PAGE);
      return (
        <div className="datasheet-view">
          <div className="admin-nav-wrapper">
            <AdminNav />
          </div>
      
          {/* Top Navigation Bar */}
          <div className="top-nav">
            <div className="left-section">
              <h1>Scenarios Datasheet</h1>
            </div>
            <div className="right-section">
              <div className="pagination-controls">
                <button onClick={() => handlePageChange(1)} disabled={currentPage === 1 || isLoading} className="btn btn-secondary">First</button>
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1 || isLoading} className="btn btn-secondary">Previous</button>
                <span>Page {currentPage} of {totalPages}</span>
                <div className="page-input-container">
                  <input
                    type="text"
                    value={pageInput}
                    onChange={handlePageInputChange}
                    className="page-input"
                    placeholder="Go to page"
                  />
                  <button 
                    className="btn btn-secondary" 
                    disabled={!pageInput || isLoading}
                    onClick={() => {
                      const page = parseInt(pageInput);
                      if (page >= 1 && page <= totalPages) {
                        handlePageChange(page);
                      }
                    }}
                  >
                    Go
                  </button>
                </div>
                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages || isLoading} className="btn btn-secondary">Next</button>
                <button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages || isLoading} className="btn btn-secondary">Last</button>
              </div>
            </div>
          </div>
      
          {/* User Info and Actions */}
          <div className="user-section">
            <span className="welcome-text">Welcome, {user?.email}</span>
            <div className="action-buttons">
              <Link to="/home" className="btn btn-secondary">
                Home View
              </Link>
              <button onClick={logout} className="btn btn-danger">
                Logout
              </button>
            </div>
          </div>
      
          {/* Filters Section */}
          <div className="filters-section">
            <div className="filters-grid">
              {/* Status Filter */}
              <div className="filter-item">
                <label>Status:</label>
                <select 
                  value={selectedStatus}
                  onChange={(e) => handleFilterChange(e.target.value, 'status')}
                  className="form-select"
                >
                  {statusOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
      
              {/* Category Filter */}
              <div className="filter-item">
                <label>Category:</label>
                <select
                  value={selectedCategory}
                  onChange={(e) => handleFilterChange(e.target.value, 'category')}
                  className="form-select"
                >
                  {categoryOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
      
              {/* Diagnosis Category Filter */}
              <div className="filter-item">
                <label>Diagnosis Category:</label>
                <select
                  value={selectedDiagnosisCategory}
                  onChange={(e) => handleFilterChange(e.target.value, 'diagnosis')}
                  className="form-select"
                >
                  {diagnosisCategoryOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
      
              {/* User Role Filter */}
              <div className="filter-item">
                <label>User Role:</label>
                <select
                  value={selectedUserRole}
                  onChange={(e) => handleFilterChange(e.target.value, 'role')}
                  className="form-select"
                >
                  {userRoleOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
      
              {/* Complexity Filter */}
              <div className="filter-item">
                <label>Complexity:</label>
                <select
                  value={selectedComplexity}
                  onChange={(e) => handleFilterChange(e.target.value, 'complexity')}
                  className="form-select"
                >
                  {complexityOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
      
              {/* Difficulty Filter */}
              <div className="filter-item">
                <label>Difficulty:</label>
                <select
                  value={selectedDifficulty}
                  onChange={(e) => handleFilterChange(e.target.value, 'difficulty')}
                  className="form-select"
                >
                  {difficultyOptions.map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
            </div>
            <button onClick={clearFilters} className="btn btn-secondary">Clear Filters</button>
          </div>
      
          {/* Table Section */}
          <div className="table-container">
            {error && (
              <div className="error-message">{error}</div>
            )}
      
            {isLoading ? (
              <div className="loading-indicator">Loading scenarios...</div>
            ) : (
              <div className="table-scroll-container" ref={tableScrollRef}>
                <table className="scenarios-table">
                  <thead>
                    <tr>
                      {columns.map(column => (
                        <th key={column.name} style={{ width: column.width, minWidth: column.width }}>
                          {column.label}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {scenarios.map(scenario => (
                      <tr key={`${scenario.scenarioid}-${scenario.order}`}>
                        <td>
                          <button onClick={() => handleEditClick(scenario)} className="btn btn-primary btn-sm">
                            Edit
                          </button>
                        </td>
                        <td>
                          <button 
                            onClick={() => {
                              setScenarioToDelete(scenario);
                              setShowDeleteConfirmation(true);
                            }} 
                            className="btn btn-danger btn-sm"
                          >
                            Delete
                          </button>
                        </td>
                        {columns.slice(2).map(column => (
                          <td key={`${scenario.scenarioid}-${scenario.order}-${column.name}`}>
                            {scenario[column.name] || ''}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
      
          {/* Delete Confirmation Modal */}
          {showDeleteConfirmation && (
            <div className="modal-overlay">
              <div className="modal-content">
                <h3>Confirm Delete</h3>
                <p>Are you sure you want to delete this scenario?</p>
                <div className="modal-actions">
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      setShowDeleteConfirmation(false);
                      setScenarioToDelete(null);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={handleDeleteScenario}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}
      
          {/* Edit Modal */}
          {showEditModal && scenarioToEdit && (
            <ScenarioEditModal
              scenario={scenarioToEdit}
              onSave={handleEditSave}
              onCancel={() => {
                setShowEditModal(false);
                setScenarioToEdit(null);
              }}
              onStatusChange={handleStatusChange}
              user={user}
            />
          )}
        </div>
      );
}

export default DatasheetView;

