import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';

const AuthContext = createContext({});

const DEVELOPMENT_MODE = true;
const DEV_USER_EMAIL = 'jim.bressoud@gmail.com';

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [requirePasswordChange, setRequirePasswordChange] = useState(false);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        if (DEVELOPMENT_MODE) {
          const devUserData = {
            email: DEV_USER_EMAIL,
            fullname: 'Jim Bressoud',
            requirePasswordChange: false,
            roles: {
              reviewer: true,
              administrator: true,
              configuration_manager: true,
              validator: true,
              scenario_generator: true,
              approver: true
            }
          };
          localStorage.setItem('userData', JSON.stringify(devUserData));
          setUser(devUserData);

          // Create a dev session
          const { data: { session: devSession }, error } = await supabase.auth.signInWithPassword({
            email: DEV_USER_EMAIL,
            password: 'dev-password' // Replace with actual dev password
          });

          if (!error) {
            setSession(devSession);
          }
        } else {
          // Check for existing session
          const { data: { session: existingSession } } = await supabase.auth.getSession();
          setSession(existingSession);

          // Get user data from localStorage
          const userData = localStorage.getItem('userData');
          if (userData) {
            const parsedData = JSON.parse(userData);
            setUser(parsedData);
            setRequirePasswordChange(parsedData.requirePasswordChange);
          }
        }
      } catch (error) {
        console.error('Auth initialization error:', error);
        localStorage.removeItem('userData');
      } finally {
        setLoading(false);
      }
    };

    initializeAuth();

    // Set up session change listener
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  const login = async (userData) => {
    if (DEVELOPMENT_MODE) {
      const devUserData = {
        email: DEV_USER_EMAIL,
        fullname: 'Jim Bressoud',
        requirePasswordChange: false,
        roles: {
          reviewer: true,
          administrator: true,
          configuration_manager: true,
          validator: true,
          scenario_generator: true,
          approver: true
        }
      };
      localStorage.setItem('userData', JSON.stringify(devUserData));
      setUser(devUserData);

      // Create auth session for development
      const { data: { session: devSession }, error } = await supabase.auth.signInWithPassword({
        email: DEV_USER_EMAIL,
        password: 'dev-password' // Replace with actual dev password
      });

      if (!error) {
        setSession(devSession);
      }

      return { success: true, requirePasswordChange: false };
    }

    try {
      // First create auth session
      const { data: { session: authSession }, error: authError } = 
        await supabase.auth.signInWithPassword({
          email: userData.email,
          password: userData.password
        });

      if (authError) throw authError;
      setSession(authSession);

      // Then get additional user attributes
      const { data: userAttributes, error } = await supabase
        .from('user_additional_attributes')
        .select(`
          email,
          fullname,
          reviewer,
          administrator,
          configuration_manager,
          validator,
          scenario_generator,
          approver,
          require_password_change
        `)
        .eq('email', userData.email)
        .single();

      if (error) {
        console.error('Database query error:', error);
        throw new Error('Failed to verify user credentials');
      }

      if (!userAttributes) {
        throw new Error('User not found in system');
      }

      const roles = {
        reviewer: userAttributes.reviewer || false,
        administrator: userAttributes.administrator || false,
        configuration_manager: userAttributes.configuration_manager || false,
        validator: userAttributes.validator || false,
        scenario_generator: userAttributes.scenario_generator || false,
        approver: userAttributes.approver || false
      };

      const hasValidRole = Object.values(roles).some(role => role === true);
      if (!hasValidRole) {
        throw new Error('Access denied: User does not have required roles');
      }

      const enrichedUserData = {
        email: userAttributes.email,
        fullname: userAttributes.fullname || userData.fullname,
        requirePasswordChange: userAttributes.require_password_change || false,
        roles
      };

      localStorage.setItem('userData', JSON.stringify(enrichedUserData));
      setUser(enrichedUserData);
      setRequirePasswordChange(enrichedUserData.requirePasswordChange);

      await supabase
        .from('audit_log')
        .insert([{
          user_email: enrichedUserData.email,
          action: 'login',
          details: `User ${enrichedUserData.fullname} logged in with roles: ${
            Object.entries(roles)
              .filter(([_, value]) => value)
              .map(([key]) => key)
              .join(', ')
          }`
        }]);

      return { 
        success: true, 
        requirePasswordChange: enrichedUserData.requirePasswordChange 
      };
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const changePassword = async (newPassword) => {
    if (DEVELOPMENT_MODE) return true;

    try {
      if (!user?.email) {
        throw new Error('No active user session');
      }

      // Update auth password
      const { error: authError } = await supabase.auth.updateUser({
        password: newPassword
      });

      if (authError) throw authError;

      // Update user attributes
      const { error } = await supabase
        .from('user_additional_attributes')
        .update({ 
          require_password_change: false,
          last_password_change: new Date().toISOString()
        })
        .eq('email', user.email);

      if (error) throw error;

      setRequirePasswordChange(false);
      const updatedUserData = {
        ...user,
        requirePasswordChange: false
      };
      localStorage.setItem('userData', JSON.stringify(updatedUserData));
      setUser(updatedUserData);

      await supabase
        .from('audit_log')
        .insert([{
          user_email: user.email,
          action: 'password_change',
          details: 'User successfully changed password'
        }]);

      return true;
    } catch (error) {
      console.error('Password change error:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      if (!DEVELOPMENT_MODE && user?.email) {
        await supabase
          .from('audit_log')
          .insert([{
            user_email: user.email,
            action: 'logout',
            details: 'User logged out'
          }]);
      }

      // Sign out from Supabase auth
      await supabase.auth.signOut();
      setSession(null);
    } catch (error) {
      console.error('Error logging logout:', error);
    } finally {
      localStorage.removeItem('userData');
      setUser(null);
      setRequirePasswordChange(false);
    }
  };

  return (
    <AuthContext.Provider value={{ 
      user,
      session, 
      login, 
      logout, 
      loading,
      requirePasswordChange,
      changePassword 
    }}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}