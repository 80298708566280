// src/components/DevelopmentModeBanner/DevelopmentModeBanner.js
import React from 'react';
import { DEVELOPMENT_MODE, DEV_USER_EMAIL } from '../../config';
import { useAuth } from '../../contexts/auth/AuthContext';
import './DevelopmentModeBanner.css';

const DevelopmentModeBanner = () => {
  const { user } = useAuth();

  if (!DEVELOPMENT_MODE || user?.email !== DEV_USER_EMAIL) {
    return null;
  }

  return (
    <div className="development-mode-banner">
      DEVELOPMENT MODE
    </div>
  );
};

export default DevelopmentModeBanner;