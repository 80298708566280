// Import any necessary constants
import { ERROR_MESSAGES } from './constants';

export const validateField = (field, value) => {
  // First, check for required fields
  if (!value && field !== 'context_details' && field !== 'initial_prompt') {
    switch (field) {
      case 'scenario_topic':
        return 'Scenario topic is required';
      case 'category':
        return 'Category is required';
      case 'diagnosiscategory':
        return 'Diagnosis category is required';
      case 'questiondiffic':
        return 'Difficulty is required';
      default:
        return 'This field is required';
    }
  }

  // Then check for specific field validations
  switch (field) {
    case 'scenario_protocols':
      if (!Array.isArray(value) || value.length === 0) {
        return 'At least one protocol must be selected';
      }
      break;
    
    case 'scenario_topic':
      if (typeof value === 'string' && value.trim().length < 3) {
        return 'Topic must be at least 3 characters long';
      }
      break;

    case 'questiondiffic':
    case 'complexity':
    case 'userrole':
      if (typeof value !== 'string' || !value) {
        return 'Please select a valid option';
      }
      break;

    default:
      break;
  }

  return null;
};

export const validateForm = (formData) => {
  const errors = {};
  const requiredFields = [
    'scenario_topic',
    'category',
    'diagnosiscategory',
    'questiondiffic',
    'complexity',
    'userrole'
  ];

  requiredFields.forEach(field => {
    const error = validateField(field, formData[field]);
    if (error) errors[field] = error;
  });

  // Add protocol validation
  if (formData.scenario_protocols) {
    const protocolError = validateField('scenario_protocols', formData.scenario_protocols);
    if (protocolError) {
      errors.scenario_protocols = protocolError;
    }
  }

  return errors;
};

export const validateScenarioConfig = (config) => {
  const errors = validateForm(config);
  return Object.keys(errors).length ? Object.values(errors).join(', ') : null;
};

// Keep original exports unchanged
export const validatePatientDetails = () => {};
export const validatePatientRecord = () => {};
export const validateQuestion = () => {};
export const validateScenario = () => {};