export const generateScenario = async ({ supabase, scenarioConfig, updateProgress }) => {
    updateProgress?.('SCENARIO_GENERATION', 'Generating scenario content', 0);
  
    try {
      const { data: scenario, error } = await supabase.functions.invoke('generate-scenario', {
        body: {
          type: 'scenario',
          parameters: scenarioConfig
        }
      });
  
      if (error) throw error;
      updateProgress?.('SCENARIO_GENERATION', 'Generated scenario', 50);
      return scenario;
    } catch (error) {
      throw new Error(`Scenario generation failed: ${error.message}`);
    }
  };
  
  export const generateQuestions = async ({ supabase, scenarioContext, scenarioConfig, updateProgress }) => {
    updateProgress?.('QUESTIONS_GENERATION', 'Generating questions', 60);
  
    try {
      const { data: questions, error } = await supabase.functions.invoke('generate-scenario', {
        body: {
          type: 'questions',
          context: scenarioContext,
          parameters: scenarioConfig
        }
      });
  
      if (error) throw error;
      updateProgress?.('QUESTIONS_GENERATION', 'Generated questions', 80);
      return questions;
    } catch (error) {
      throw new Error(`Questions generation failed: ${error.message}`);
    }
  };
  
  export const saveToDatabase = async ({ supabase, scenario, questions, scenarioConfig, updateProgress }) => {
    updateProgress?.('SAVING', 'Saving to database', 90);
  
    try {
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
  
      const { data: scenarioData, error: scenarioError } = await supabase
        .from('scenarios')
        .insert([{
          title: scenario.title,
          content: scenario.content,
          diagnosiscategory: scenarioConfig.diagnosisCategory,
          difficulty: scenarioConfig.complexity,
          provider_type: scenarioConfig.providerType,
          protocol_ids: scenarioConfig.selectedProtocols,
          created_at: new Date(),
          updated_at: new Date()
        }])
        .select()
        .single();
  
      if (scenarioError) throw scenarioError;
  
      await Promise.all([
        // Save questions
        supabase
          .from('scenario_questions')
          .insert(questions.map((question, index) => ({
            scenario_id: scenarioData.id,
            question_text: question.text,
            options: question.options,
            correct_answer: question.correct_answer,
            explanation: question.explanation,
            order: index + 1
          }))),
  
        // Save search criteria
        supabase
          .from('claudescenarioscript')
          .insert([{
            scenario_id: scenarioData.id,
            diagnosis_category: scenarioConfig.diagnosisCategory,
            complexity: scenarioConfig.complexity,
            provider_type: scenarioConfig.providerType,
            num_questions: scenarioConfig.numberOfQuestions,
            initial_prompts: scenarioConfig.initialPrompts,
            additional_context: scenarioConfig.additionalContext,
            selected_protocols: scenarioConfig.selectedProtocols,
            number_of_scenarios: scenarioConfig.numberOfScenarios,
            created_at: new Date(),
            created_by: user?.id
          }])
      ]);
  
      updateProgress?.('COMPLETE', 'Save complete', 100);
      return scenarioData.id;
    } catch (error) {
      throw new Error(`Database save failed: ${error.message}`);
    }
  };
  
  export const generateCompleteScenario = async (params) => {
    const { supabase, scenarioConfig, updateProgress } = params;
  
    try {
      const scenario = await generateScenario({ supabase, scenarioConfig, updateProgress });
      const questions = await generateQuestions({ 
        supabase, 
        scenarioContext: scenario, 
        scenarioConfig, 
        updateProgress 
      });
      const scenarioId = await saveToDatabase({ 
        supabase, 
        scenario, 
        questions, 
        scenarioConfig, 
        updateProgress 
      });
  
      return scenarioId;
    } catch (error) {
      throw new Error(`Scenario generation process failed: ${error.message}`);
    }
  };