import { useAuth } from './AuthContext';

const DEFAULT_USER_ROLES = { user: true };

export const ROLE_CAPABILITIES = {
 administrator: {
   access_datasheet: true,
   validate_scenarios: true,
   generate_scenarios: true,
   import_csv: true,
   edit_scenarios: true,
   delete_scenarios: true,
   access_admin: true,
   view_scenarios: true
 },
 reviewer: {
   access_datasheet: true,
   edit_scenarios: true,
   view_scenarios: true
 },
 user: {
   view_scenarios: true
 }
};

export const hasCapability = (capability, roles = DEFAULT_USER_ROLES) => {
 return Object.entries(roles).some(([role, isActive]) => {
   return isActive && ROLE_CAPABILITIES[role]?.[capability];
 });
};

export const useRoleBasedAccess = () => {
 const { user } = useAuth();
 const roles = user?.roles || DEFAULT_USER_ROLES;

 return {
   canAccessDatasheet: hasCapability('access_datasheet', roles),
   canValidateScenarios: hasCapability('validate_scenarios', roles),
   canGenerateScenarios: hasCapability('generate_scenarios', roles),
   canImportCSV: hasCapability('import_csv', roles),
   canEditScenarios: hasCapability('edit_scenarios', roles),
   canDeleteScenarios: hasCapability('delete_scenarios', roles),
   canAccessAdmin: hasCapability('access_admin', roles),
   canViewScenarios: hasCapability('view_scenarios', roles),
   userRoles: roles
 };
};

export const getAllowedRoutes = (roles = DEFAULT_USER_ROLES) => {
 const routes = new Set(['/home']); // All users can access home
 
 if (hasCapability('access_datasheet', roles)) routes.add('/datasheet');
 if (hasCapability('validate_scenarios', roles)) routes.add('/validation'); 
 if (hasCapability('access_admin', roles)) routes.add('/admin');
 if (hasCapability('view_scenarios', roles)) routes.add('/scenarios');
 
 return Array.from(routes);
};