import { useState } from 'react';

export const useProgress = () => {
  const [progress, setProgress] = useState({
    phase: '',
    subPhase: '',
    progress: 0,
    startTime: null,
    estimatedTimeRemaining: null,
    metrics: {
      totalApiCalls: 0,
      successfulApiCalls: 0,
      averageLatency: 0,
      validationsPassed: 0,
      validationsFailed: 0,
      retryAttempts: 0
    }
  });

  const updateProgress = (phase, subPhase, progressValue) => {
    const now = Date.now();
    setProgress(prev => {
      const startTime = prev.startTime || now;
      const timeElapsed = now - startTime;
      const estimatedTotalTime = (timeElapsed * 100) / Math.max(progressValue, 1);
      const estimatedTimeRemaining = Math.max(0, estimatedTotalTime - timeElapsed);

      return {
        ...prev,
        phase,
        subPhase,
        progress: progressValue,
        startTime: prev.startTime || now,
        estimatedTimeRemaining: estimatedTimeRemaining / 1000,
        metrics: {
          ...prev.metrics,
          successRate: prev.metrics.successfulApiCalls / Math.max(prev.metrics.totalApiCalls, 1)
        }
      };
    });
  };

  const resetProgress = () => {
    setProgress({
      phase: '',
      subPhase: '',
      progress: 0,
      startTime: null,
      estimatedTimeRemaining: null,
      metrics: {
        totalApiCalls: 0,
        successfulApiCalls: 0,
        averageLatency: 0,
        validationsPassed: 0,
        validationsFailed: 0,
        retryAttempts: 0
      }
    });
  };

  return {
    progress,
    updateProgress,
    resetProgress
  };
};