import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './ProtocolEdit.css';

const ProtocolEdit = ({ supabase }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    keywords: [],
    active: true
  });
  
  const [keywordInput, setKeywordInput] = useState('');

  const fetchProtocol = useCallback(async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('protocols')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;
      
      setFormData({
        title: data.title,
        content: data.content,
        keywords: data.keywords || [],
        active: data.active
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [id, supabase]);

  useEffect(() => {
    fetchProtocol();
  }, [fetchProtocol]);

  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { error } = await supabase
        .from('protocols')
        .update({
          ...formData,
          updated_at: new Date().toISOString()
        })
        .eq('id', id);

      if (error) throw error;
      
      navigate('/admin');
    } catch (err) {
      setError(err.message);
    }
  };

  const addKeyword = () => {
    if (keywordInput.trim()) {
      const newKeyword = keywordInput.trim().toLowerCase();
      if (!formData.keywords.includes(newKeyword)) {
        handleInputChange('keywords', [...formData.keywords, newKeyword]);
      }
      setKeywordInput('');
    }
  };

  const removeKeyword = (keywordToRemove) => {
    handleInputChange(
      'keywords', 
      formData.keywords.filter(k => k !== keywordToRemove)
    );
  };

  if (loading) return <div className="loading-container">Loading...</div>;
  if (error) return <div className="error-container">Error: {error}</div>;

  return (
    <div className="protocol-edit-container">
      <form onSubmit={handleSubmit} className="edit-form">
        <div className="form-header">
          <h1>Edit Protocol</h1>
          <button 
            type="button"
            onClick={() => navigate('/admin')} 
            className="form-close"
          >
            ×
          </button>
        </div>

        <div className="form-content">
          <div className="form-group">
            <label htmlFor="title">Protocol Title</label>
            <input
              id="title"
              type="text"
              value={formData.title}
              onChange={(e) => handleInputChange('title', e.target.value)}
              className="form-control"
              required
            />
          </div>

          <div className="form-group">
            <label>Status</label>
            <div className="status-group">
              <input
                type="checkbox"
                checked={formData.active}
                onChange={(e) => handleInputChange('active', e.target.checked)}
                className="checkbox-control"
                id="status"
              />
              <label htmlFor="status">Active Protocol</label>
            </div>
          </div>

          <div className="form-group">
            <label>Keywords</label>
            <div className="keywords-section">
              <div className="keyword-input-group">
                <input
                  type="text"
                  value={keywordInput}
                  onChange={(e) => setKeywordInput(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      addKeyword();
                    }
                  }}
                  className="form-control keyword-input"
                  placeholder="Type keyword and press Enter"
                />
                <button
                  type="button"
                  onClick={addKeyword}
                  className="btn btn-secondary"
                >
                  Add
                </button>
              </div>
              <div className="keywords-list">
                {formData.keywords.map((keyword, index) => (
                  <div key={index} className="keyword-tag">
                    #{keyword}
                    <button
                      type="button"
                      onClick={() => removeKeyword(keyword)}
                      className="remove-keyword"
                    >
                      ×
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="content">Protocol Content</label>
            <textarea
              id="content"
              value={formData.content}
              onChange={(e) => handleInputChange('content', e.target.value)}
              className="form-control"
              rows="12"
              required
            />
          </div>
        </div>

        <div className="form-actions">
          <button
            type="button"
            onClick={() => navigate('/admin')}
            className="btn btn-secondary"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProtocolEdit;