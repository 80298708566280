import { useState, useRef, useEffect, useCallback } from 'react';
import { useProgress } from './useProgress';
import { generateCompleteScenario } from '../utils/scenarioGenerator';
import { retryOperation } from '../utils/helpers';

const GENERATION_TIMEOUT = 300000; // 5 minutes

export const useScenarioGeneration = ({
  supabase,
  onComplete,
  onError
}) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);
  const timeoutRef = useRef(null);

  const {
    progress: generationProgress,
    updateProgress,
    resetProgress
  } = useProgress();

  const handleError = (error, context) => {
    console.error(`Error in ${context}:`, error);
    setError({
      message: error.message,
      context,
      timestamp: new Date()
    });
    if (onError) onError(error);
  };

  const startGeneration = async (scenarioConfig) => {
    setIsGenerating(true);
    setError(null);
    resetProgress();

    timeoutRef.current = setTimeout(() => {
      handleError(new Error('Generation timed out'), 'generation_timeout');
      setIsGenerating(false);
    }, GENERATION_TIMEOUT);

    try {
      const scenarioId = await generateCompleteScenario({
        supabase,
        scenarioConfig,
        updateProgress
      });

      clearTimeout(timeoutRef.current);
      setIsGenerating(false);
      if (onComplete) onComplete(scenarioId);

    } catch (error) {
      handleError(error, 'generation_process');
      setIsGenerating(false);
    } finally {
      clearTimeout(timeoutRef.current);
    }
  };

  const handleCancel = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsGenerating(false);
    resetProgress();
  }, [resetProgress]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return {
    isGenerating,
    error,
    generationProgress,
    startGeneration,
    handleCancel
  };
};