import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import LoginScreen from './components/LoginScreen/LoginScreen';
import HomeScreen from './components/HomeScreen/HomeScreen';
import DatasheetView from './components/DatasheetView/DatasheetView';
import ValidationView from './components/ValidationView/ValidationView';
import { AuthProvider } from './contexts/auth/AuthContext';
import AdminPage from './components/admin/AdminPage';
import './styles/shared.css';
import ProtocolEdit from './components/admin/ProtocolEdit';
import { supabase } from './supabaseClient';  


function App() {
  return (
    <AuthProvider>
    <Router>
        <Routes>
          <Route path="/" element={<LoginScreen />} />
          <Route path="/login" element={<Navigate to="/" replace />} />
          <Route path="/home" element={
            <ProtectedRoute>
              <HomeScreen />
            </ProtectedRoute>
          } />
          <Route path="/datasheet" element={
            <ProtectedRoute>
              <DatasheetView />
            </ProtectedRoute>
          } />
          <Route path="/admin" element={
            <ProtectedRoute>
              <AdminPage />
            </ProtectedRoute>
          } />
          <Route path="/admin/protocols/edit/:id" element={<ProtocolEdit supabase={supabase} />} />
          <Route path="/validation" element={
            <ProtectedRoute>
              <ValidationView />
            </ProtectedRoute>
          } />
        </Routes>
    </Router>
      </AuthProvider>
);
}

export default App;