import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { useAuth } from '../../contexts/auth/AuthContext';
import { useRoleBasedAccess } from '../../contexts/auth/roleUtils';
import DevelopmentModeBanner from '../../components/DevelopmentModeBanner';
import './HomeScreen.css';

// Constants
const PREDEFINED_STATUSES = [
  null,
  "Reviewed",
  "Bring to Jim's Attention",
  "Bring to Kevin's Attention",
  "Bring to Lhexi's Attention",
  "Needs Additional Question(s)",
  "Needs Additional Review",
  "Needs Image File",
  "Question should be eliminated",
  "Scenario should be eliminated"
];

const SCENARIOS_PER_PAGE = 25;
const FIELDS_TO_HIDE = ['p', 'r', 'bp', 'spo2', 'bg', 'scenario_table_id'];
const LARGE_TEXT_FIELDS = [
  'patientreport', 'question', 'option1', 'option2', 'option3', 'option4',
  'explanation1', 'explanation2', 'explanation3', 'explanation4', 'fulltext',
  'tags', 'meds', 'exam', 'physicalexam', 'comments'
];
const MEDIUM_TEXT_FIELDS = ['image'];

function HomeScreen() {
  // State declarations
  const [scenarios, setScenarios] = useState([]);
  const [currentScenario, setCurrentScenario] = useState(null);
  const [statuses, setStatuses] = useState(['All Statuses']);
  const [category, setCategories] = useState(['All Categories']);
  const [diagnosisCategories, setDiagnosisCategories] = useState(['All Diagnosis Categories']);
  const [selectedStatus, setSelectedStatus] = useState('All Statuses');
  const [selectedCategory, setSelectedCategory] = useState('All Categories');
  const [selectedDiagnosisCategory, setSelectedDiagnosisCategory] = useState('All Diagnosis Categories');
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState(null);
  const [isAddingQuestion, setIsAddingQuestion] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [currentResultIndex, setCurrentResultIndex] = useState(0);
  const [updatedStatus, setUpdatedStatus] = useState('');
  const [tagError, setTagError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalScenarios, setTotalScenarios] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [scenarioToDelete, setScenarioToDelete] = useState(null);

  const { user, logout } = useAuth();
  const { canAccessDatasheet, isAdmin } = useRoleBasedAccess();
  // Utility Functions
  const fetchWithRetry = async (queryFunction, maxRetries = 3, delay = 1000) => {
    for (let i = 0; i < maxRetries; i++) {
      try {
        return await queryFunction();
      } catch (error) {
        if (i === maxRetries - 1) throw error;
        await new Promise(resolve => setTimeout(resolve, delay * Math.pow(2, i)));
      }
    }
  };

  const parseTagsFromString = (tagsString) => {
    if (!tagsString) return [];
    return tagsString.split(',')
      .map(tag => tag.trim())
      .filter(tag => tag !== '')
      .map(tag => tag.startsWith('#') ? tag : `#${tag}`);
  };

  const formatTagsForDisplay = (tagsArray) => {
    if (!tagsArray || !Array.isArray(tagsArray)) return '';
    return tagsArray.join(', ');
  };

  const getFieldLabel = (key) => {
    const labels = {
      patientreport: 'Patient Report',
      question: 'Question',
      option1: 'Option 1',
      option2: 'Option 2',
      option3: 'Option 3',
      option4: 'Option 4',
      explanation1: 'Explanation 1',
      explanation2: 'Explanation 2',
      explanation3: 'Explanation 3',
      explanation4: 'Explanation 4',
      fulltext: 'Full Text',
      tags: 'Tags',
      meds: 'Medications',
      exam: 'Exam',
      physicalexam: 'Physical Exam',
      comments: 'Comments',
      scenarioid: 'Scenario ID',
      order: 'Order',
      category: 'Category',
      diagnosiscategory: 'Diagnosis Category',
      title: 'Title',
      status: 'Status',
      answer: 'Answer',
      image: 'Image',
      dateadded: 'Date Added'
    };
    return labels[key] || key.charAt(0).toUpperCase() + key.slice(1);
  };

  // Data Fetching Functions
  const fetchTotalCount = useCallback(async () => {
    try {
      let query = supabase
        .from('scenarios')
        .select('*', { count: 'exact', head: true });

      if (selectedStatus !== 'All Statuses') {
        query = query.eq('status', selectedStatus);
      }
      if (selectedCategory !== 'All Categories') {
        query = query.eq('category', selectedCategory);
      }
      if (selectedDiagnosisCategory !== 'All Diagnosis Categories') {
        query = query.eq('diagnosiscategory', selectedDiagnosisCategory);
      }
      if (searchTerm) {
        const sanitizedSearch = searchTerm.replace(/[%_]/g, '\\$&');
        query = query.or(
          `title.ilike.%${sanitizedSearch}%,` +
          `patientreport.ilike.%${sanitizedSearch}%,` +
          `question.ilike.%${sanitizedSearch}%`
        );

        if (!isNaN(parseInt(searchTerm))) {
          query = query.or(`scenarioid.eq.${parseInt(searchTerm)}`);
        }
      }

      const { count } = await query;
      setTotalScenarios(count || 0);
    } catch (error) {
      console.error('Error fetching total count:', error);
      setError(`Failed to fetch total count: ${error.message}`);
    }
  }, [selectedStatus, selectedCategory, selectedDiagnosisCategory, searchTerm]);

  const fetchCategories = useCallback(async () => {
    try {
      const { data: categoryData, error: categoryError } = await fetchWithRetry(() => 
        supabase
          .from('scenarios')
          .select('category')
          .not('category', 'is', null)
      );
      
      if (categoryError) throw categoryError;
      
      const { data: diagnosisData, error: diagnosisError } = await fetchWithRetry(() => 
        supabase
          .from('scenarios')
          .select('diagnosiscategory')
          .not('diagnosiscategory', 'is', null)
      );

      if (diagnosisError) throw diagnosisError;

      const uniqueCategories = [...new Set(categoryData
        .map(item => item?.category)
        .filter(Boolean))].sort();
      
      const uniqueDiagnosis = [...new Set(diagnosisData
        .map(item => item?.diagnosiscategory)
        .filter(Boolean))].sort();

      setCategories(['All Categories', ...uniqueCategories]);
      setDiagnosisCategories(['All Diagnosis Categories', ...uniqueDiagnosis]);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError(`Failed to fetch categories: ${error.message}`);
    }
  }, []);

  const fetchStatuses = useCallback(async () => {
    try {
      const { data, error } = await fetchWithRetry(() => 
        supabase
          .from('scenarios')
          .select('status')
      );
      
      if (error) throw error;
      
      const existingStatuses = [...new Set(data.map(item => item?.status).filter(Boolean))];
      const combinedStatuses = [...new Set([...PREDEFINED_STATUSES, ...existingStatuses])];
      
      const sortedStatuses = combinedStatuses.sort((a, b) => {
        if (a === null) return -1;
        if (b === null) return 1;
        if (a === "Reviewed") return -1;
        if (b === "Reviewed") return 1;
        return a.localeCompare(b);
      });

      setStatuses(['All Statuses', ...sortedStatuses]);
    } catch (error) {
      console.error('Error fetching statuses:', error);
      setError(`Failed to fetch statuses: ${error.message}`);
    }
  }, []);

  const fetchScenarios = useCallback(async () => {
    setIsLoading(true);
    try {
      let query = supabase
        .from('scenarios')
        .select('*');
      
      if (selectedStatus !== 'All Statuses') {
        query = query.eq('status', selectedStatus);
      }
      if (selectedCategory !== 'All Categories') {
        query = query.eq('category', selectedCategory);
      }
      if (selectedDiagnosisCategory !== 'All Diagnosis Categories') {
        query = query.eq('diagnosiscategory', selectedDiagnosisCategory);
      }
      if (searchTerm) {
        const sanitizedSearch = searchTerm.replace(/[%_]/g, '\\$&');
        query = query.or(
          `title.ilike.%${sanitizedSearch}%,` +
          `patientreport.ilike.%${sanitizedSearch}%,` +
          `question.ilike.%${sanitizedSearch}%`
        );

        if (!isNaN(parseInt(searchTerm))) {
          query = query.or(`scenarioid.eq.${parseInt(searchTerm)}`);
        }
      }

      query = query
        .order('scenarioid', { ascending: true })
        .order('order', { ascending: true })
        .range((currentPage - 1) * SCENARIOS_PER_PAGE, currentPage * SCENARIOS_PER_PAGE - 1);

      const { data, error } = await query;
      
      if (error) throw error;

      const formattedData = data.map(scenario => ({
        ...scenario,
        tags: formatTagsForDisplay(scenario.tags)
      }));

      setScenarios(formattedData);
      setSearchResults(formattedData);
      setCurrentResultIndex(0);
      
      if (formattedData.length > 0 && !currentScenario) {
        setCurrentScenario(formattedData[0]);
      }
      
      await fetchTotalCount();
    } catch (error) {
      console.error('Error fetching scenarios:', error);
      setError(`Failed to fetch scenarios: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [selectedStatus, selectedCategory, selectedDiagnosisCategory, searchTerm, currentPage, fetchTotalCount]);


  // Event Handlers
  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchScenarios();
  };

  const handleStatusUpdate = async () => {
    if (!currentScenario) return;
    try {
      const now = new Date().toISOString();
      const statusChangeComment = `Status changed to ${updatedStatus} by ${user.email} on ${now}`;
      const updatedComments = currentScenario.comments
        ? `${currentScenario.comments}\n${statusChangeComment}`
        : statusChangeComment;

      const { data, error } = await supabase
        .from('scenarios')
        .update({
          status: updatedStatus,
          comments: updatedComments
        })
        .eq('id', currentScenario.id)
        .select();

      if (error) throw error;

      setCurrentScenario({
        ...data[0],
        tags: formatTagsForDisplay(data[0].tags)
      });
      fetchScenarios();
      
      await logAudit('update_status', `Updated status for scenario ${currentScenario.id} to ${updatedStatus}`);
    } catch (error) {
      console.error('Error updating status:', error);
      setError(`Failed to update status: ${error.message}`);
    }
  };

  const handleDeleteScenario = async () => {
    if (!scenarioToDelete) return;
    
    try {
      setIsLoading(true);
      
      const { error: deleteError } = await supabase
        .rpc('delete_scenario', {
          p_scenarioid: scenarioToDelete.scenarioid,
          p_order: scenarioToDelete.order
        });
  
      if (deleteError) throw deleteError;
  
      await logAudit('delete_question', `Deleted question ${scenarioToDelete.scenarioid}-${scenarioToDelete.order}`);
      
      setScenarios(prev => prev.filter(s => 
        !(s.scenarioid === scenarioToDelete.scenarioid && s.order === scenarioToDelete.order)
      ));
      
      if (currentScenario?.scenarioid === scenarioToDelete.scenarioid && 
          currentScenario?.order === scenarioToDelete.order) {
        const remainingScenarios = scenarios.filter(s => 
          !(s.scenarioid === scenarioToDelete.scenarioid && s.order === scenarioToDelete.order)
        );
        setCurrentScenario(remainingScenarios.length > 0 ? remainingScenarios[0] : null);
      }
      
      setScenarioToDelete(null);
      setShowDeleteConfirmation(false);
      
      await fetchTotalCount();
      await fetchScenarios();
      
    } catch (error) {
      console.error('Error deleting question:', error);
      setError('Failed to delete question: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleScenarioChange = (e) => {
    const selectedScenario = scenarios.find(s => s.id.toString() === e.target.value);
    setCurrentScenario(selectedScenario);
    setEditMode(false);
    setIsAddingQuestion(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'tags') {
      setTagError('');
    }
    
    setCurrentScenario(prev => ({ ...prev, [name]: value }));
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setCurrentPage(1);
    fetchScenarios();
  };

  const handleDiagnosisCategoryChange = (e) => {
    setSelectedDiagnosisCategory(e.target.value);
    setCurrentPage(1);
    fetchScenarios();
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
    setCurrentPage(1);
    fetchScenarios();
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(totalScenarios / SCENARIOS_PER_PAGE) && !isLoading) {
      setCurrentPage(newPage);
      fetchScenarios();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isAddingQuestion) {
      await saveNewQuestion();
    } else {
      await updateScenario(currentScenario);
    }
  };

  const handleNextResult = () => {
    if (currentResultIndex < searchResults.length - 1) {
      const nextIndex = currentResultIndex + 1;
      setCurrentResultIndex(nextIndex);
      setCurrentScenario(searchResults[nextIndex]);
    }
  };

  const handlePrevious = () => {
    const currentIndex = scenarios.findIndex(s => s.id === currentScenario.id);
    if (currentIndex > 0) {
      setCurrentScenario(scenarios[currentIndex - 1]);
      setIsAddingQuestion(false);
      setEditMode(false);
    }
  };

  const handleNext = () => {
    const currentIndex = scenarios.findIndex(s => s.id === currentScenario.id);
    if (currentIndex < scenarios.length - 1) {
      setCurrentScenario(scenarios[currentIndex + 1]);
      setIsAddingQuestion(false);
      setEditMode(false);
    }
  };

  const handleAddQuestion = () => {
    if (!currentScenario) return;

    const newScenarioId = currentScenario.scenarioid;
    const newOrder = Math.max(...scenarios
      .filter(s => s.scenarioid === newScenarioId)
      .map(s => s.order)) + 1;

    const newQuestion = {
      scenarioid: newScenarioId,
      order: newOrder,
      category: currentScenario.category,
      diagnosiscategory: currentScenario.diagnosiscategory,
      title: currentScenario.title,
      patientreport: currentScenario.patientreport,
      status: 'New',
      question: '',
      option1: '',
      option2: '',
      option3: '',
      option4: '',
      explanation1: '',
      explanation2: '',
      explanation3: '',
      explanation4: '',
      answer: '',
      fulltext: '',
      image: '',
      dateadded: new Date().toISOString().split('T')[0],
      tags: '',
      meds: '',
      exam: '',
      physicalexam: '',
      comments: ''
    };

    setCurrentScenario(newQuestion);
    setIsAddingQuestion(true);
    setEditMode(true);
  };

  const saveNewQuestion = async () => {
    try {
      const { id, ...questionData } = currentScenario;
      const tagsArray = parseTagsFromString(questionData.tags);
      
      const questionToSave = {
        ...questionData,
        tags: tagsArray
      };
      
      const { data, error } = await supabase
        .from('scenarios')
        .insert(questionToSave)
        .select();

      if (error) throw error;

      const formattedData = {
        ...data[0],
        tags: formatTagsForDisplay(data[0].tags)
      };

      setScenarios([...scenarios, formattedData]);
      setCurrentScenario(formattedData);
      setIsAddingQuestion(false);
      setEditMode(false);
      fetchScenarios();
      
      await logAudit('add_question', `Added new question to scenario ${data[0].scenarioid}`);
    } catch (error) {
      console.error('Error adding new question:', error);
      setError(`Failed to add new question: ${error.message}`);
    }
  };

  const updateScenario = async (updatedScenario) => {
    try {
      let tagsArray = [];
      if (updatedScenario.tags) {
        tagsArray = parseTagsFromString(updatedScenario.tags);
        
        const invalidTags = tagsArray.filter(tag => !tag.startsWith('#'));
        if (invalidTags.length > 0) {
          setTagError('All tags must start with # symbol');
          return;
        }
      }

      const { id, ...scenarioWithoutId } = {
        ...updatedScenario,
        tags: tagsArray
      };
      
      const { data, error } = await supabase
        .from('scenarios')
        .update(scenarioWithoutId)
        .eq('id', id)
        .select();

      if (error) throw error;
      
      setCurrentScenario({
        ...data[0],
        tags: formatTagsForDisplay(data[0].tags)
      });
      setEditMode(false);
      fetchScenarios();
      
      await logAudit('update_scenario', `Updated scenario ${id}`);
    } catch (error) {
      console.error('Error updating scenario:', error);
      setError(`Failed to update scenario: ${error.message}`);
    }
  };

  const logAudit = async (action, details) => {
    try {
      const { error } = await supabase
        .from('audit_log')
        .insert({ user_email: user.email, action, details });
      if (error) throw error;
    } catch (error) {
      console.error('Error logging audit:', error.message);
    }
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const getExplanationStyle = (key) => {
    if (!currentScenario || !currentScenario.answer) return '';
    const explanationNumber = key.replace('explanation', '');
    return explanationNumber === String(currentScenario.answer) ? 'explanation-correct' : 'explanation-incorrect';
  };
// Effects
useEffect(() => {
  fetchCategories();
  fetchScenarios();
  fetchStatuses();
}, [fetchCategories, fetchScenarios, fetchStatuses]);

useEffect(() => {
  if (currentScenario) {
    setUpdatedStatus(currentScenario.status || '');
  }
}, [currentScenario]);

if (error) {
  return <div className="error">{error}</div>;
}

return (
  <>
    <DevelopmentModeBanner />
    <div className="home-screen">
      {/* Header Section with improved layout */}
      <div className="header-container">
        <div className="header-top">
          {isAdmin && (
            <Link to="/admin" className="admin-icon" title="Admin Dashboard">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" 
                   stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <circle cx="12" cy="12" r="3"/>
                <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"/>
              </svg>
            </Link>
          )}
        </div>

        <div className="header-content">
          <h1 className="header-title">Question Review Form</h1>
          <div className="welcome-text">Welcome, {user?.email}</div>
        </div>

        <div className="header-actions">
          {canAccessDatasheet && (
            <Link to="/datasheet" className="btn btn-primary">
              Switch to Datasheet View
            </Link>
          )}
          <button className="btn btn-danger" onClick={logout}>
            Logout
          </button>
        </div>
      </div>

      {/* Filter Section */}
      <div className="card">
        <h2 className="card-title">Filter</h2>
        <div className="filter-grid">
          <div className="filter-group">
            <label>Category:</label>
            <select value={selectedCategory} onChange={handleCategoryChange}>
              {category.map(cat => (
                <option key={cat} value={cat}>{cat}</option>
              ))}
            </select>
          </div>
          <div className="filter-group">
            <label>Diagnosis Category:</label>
            <select value={selectedDiagnosisCategory} onChange={handleDiagnosisCategoryChange}>
              {diagnosisCategories.map(cat => (
                <option key={cat} value={cat}>{cat}</option>
              ))}
            </select>
          </div>
          <div className="filter-group">
            <label>Filter by Status:</label>
            <select value={selectedStatus} onChange={handleStatusChange}>
              {statuses.map((status, index) => (
                <option key={index} value={status || ''}>
                  {status === null ? '(No Status)' : status}
                </option>
              ))}
            </select>
          </div>
          <div className="filter-group">
            <label>Select Question:</label>
            <select 
              value={currentScenario?.id || ''} 
              onChange={handleScenarioChange}
              disabled={!scenarios.length}
            >
              {scenarios.map(scenario => (
                <option key={scenario.id} value={scenario.id}>
                  {scenario.scenarioid}-{scenario.order}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {/* Search Section */}
      <div className="card">
        <div className="search-container">
          <form onSubmit={handleSearch} className="search-form">
            <input
              type="text"
              placeholder="Search by scenarioId, title, patient report, or question"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
            <button type="submit" className="btn btn-primary">
              Search
            </button>
          </form>
        </div>

        {/* Navigation */}
        <div className="navigation-section">
          <div className="results-info">
            Showing result {currentPage} of {Math.ceil(totalScenarios / SCENARIOS_PER_PAGE)}
          </div>
          <div className="button-row">
            <button 
              className="btn btn-secondary"
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            >
              First
            </button>
            <button 
              className="btn btn-secondary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button 
              className="btn btn-secondary"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === Math.ceil(totalScenarios / SCENARIOS_PER_PAGE)}
            >
              Next
            </button>
            <button 
              className="btn btn-secondary"
              onClick={() => handlePageChange(Math.ceil(totalScenarios / SCENARIOS_PER_PAGE))}
              disabled={currentPage === Math.ceil(totalScenarios / SCENARIOS_PER_PAGE)}
            >
              Last
            </button>
          </div>
        </div>
      </div>

      {/* Question Controls */}
      <div className="card">
        <div className="button-row">
          <button 
            className="btn btn-secondary"
            onClick={handlePrevious}
            disabled={!currentScenario || scenarios.indexOf(currentScenario) === 0}
          >
            Previous Question
          </button>
          <button 
            className="btn btn-primary"
            onClick={handleAddQuestion}
            disabled={!currentScenario}
          >
            Add Question
          </button>
          <button 
            className="btn btn-danger"
            onClick={() => {
              setScenarioToDelete(currentScenario);
              setShowDeleteConfirmation(true);
            }}
            disabled={!currentScenario}
          >
            Delete Question
          </button>
          <button 
            className="btn btn-secondary"
            onClick={handleNext}
            disabled={!currentScenario || scenarios.indexOf(currentScenario) === scenarios.length - 1}
          >
            Next Question
          </button>
        </div>
      </div>

      {/* Question Details */}
      {isLoading ? (
        <div className="loading-indicator">Loading...</div>
      ) : currentScenario ? (
        <div className="card question-details">
          <div className="card-header">
            <h2>{isAddingQuestion ? 'Add New Question' : `Scenario ${currentScenario.scenarioid}-${currentScenario.order}`}</h2>
            <div className="button-row">
              <button 
                className="btn btn-secondary"
                onClick={toggleEditMode}
                disabled={isAddingQuestion}
              >
                {editMode ? 'Cancel Edit' : 'Edit'}
              </button>
              {(editMode || isAddingQuestion) && (
                <button className="btn btn-success" onClick={handleSubmit}>
                  Save Changes
                </button>
              )}
            </div>
          </div>

          <form className="scenario-form" onSubmit={handleSubmit}>
            {Object.entries(currentScenario)
              .filter(([key]) => !FIELDS_TO_HIDE.includes(key))
              .map(([key, value]) => (
                <div key={key} className={`form-group ${
                  LARGE_TEXT_FIELDS.includes(key) ? 'large-field' : ''
                } ${MEDIUM_TEXT_FIELDS.includes(key) ? 'medium-field' : ''}`}>
                  <label>{getFieldLabel(key)}:</label>
                  {LARGE_TEXT_FIELDS.includes(key) || MEDIUM_TEXT_FIELDS.includes(key) ? (
                    <textarea
                      name={key}
                      value={value || ''}
                      onChange={handleInputChange}
                      readOnly={!editMode && !isAddingQuestion}
                      className={`form-control ${
                        key.startsWith('explanation') ? getExplanationStyle(key) : ''
                      }`}
                    />
                  ) : (
                    <input
                      type={key === 'dateadded' ? 'date' : 'text'}
                      name={key}
                      value={value || ''}
                      onChange={handleInputChange}
                      readOnly={!editMode && !isAddingQuestion || key === 'scenarioid' || key === 'order'}
                      className="form-control"
                    />
                  )}
                </div>
              ))}
          </form>
        </div>
      ) : (
        <div className="no-scenarios">No scenarios found</div>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteConfirmation && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Confirm Delete</h3>
            <p>Are you sure you want to delete this question?</p>
            <p>Scenario ID: {scenarioToDelete?.scenarioid}-{scenarioToDelete?.order}</p>
            <div className="modal-actions">
              <button
                className="btn btn-secondary"
                onClick={() => {
                  setShowDeleteConfirmation(false);
                  setScenarioToDelete(null);
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger"
                onClick={handleDeleteScenario}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  </>
);
}

export default HomeScreen;
