import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from './ui/Progress';

export const GenerationProgress = ({ progress, onCancel, status }) => {
  return (
    <div className="generation-progress">
      <div className="progress-header">
        <h3>Generating Scenario</h3>
        {status && <p className="status-text">{status}</p>}
      </div>
      
      <div className="progress-container">
        <Progress value={progress} />
        <span className="progress-value">{Math.round(progress)}%</span>
      </div>

      {onCancel && (
        <button
          onClick={onCancel}
          className="cancel-button"
          type="button"
        >
          Cancel Generation
        </button>
      )}
    </div>
  );
};

GenerationProgress.propTypes = {
  progress: PropTypes.number.isRequired,
  onCancel: PropTypes.func,
  status: PropTypes.string
};

// Add both named and default exports
export default GenerationProgress;