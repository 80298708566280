import { useRef, useCallback } from 'react';

export const useMetrics = () => {
  const operationTimersRef = useRef(new Map());
  const metricsRef = useRef({
    apiCalls: [],
    validations: [],
    generations: [],
    events: [],
    errors: []
  });
  
  // Add debouncing mechanism
  const lastEventRef = useRef(new Map());
  const DEBOUNCE_INTERVAL = 1000; // 1 second debounce

  const startOperation = useCallback((operationType) => {
    const operationId = `${operationType}_${Date.now()}`;
    operationTimersRef.current.set(operationId, {
      type: operationType,
      startTime: performance.now()
    });
    return operationId;
  }, []);

  const endOperation = useCallback((operationId) => {
    const operation = operationTimersRef.current.get(operationId);
    if (operation) {
      const endTime = performance.now();
      const duration = endTime - operation.startTime;
      
      metricsRef.current[`${operation.type}s`].push({
        duration,
        timestamp: Date.now()
      });

      operationTimersRef.current.delete(operationId);
      return duration;
    }
    return 0;
  }, []);

  const trackEvent = useCallback((eventName, data = {}) => {
    try {
      const now = Date.now();
      const lastEvent = lastEventRef.current.get(eventName);
      
      // Check if this event should be debounced
      if (lastEvent && (now - lastEvent.timestamp) < DEBOUNCE_INTERVAL) {
        // If the data is the same, skip this event
        if (JSON.stringify(lastEvent.data) === JSON.stringify(data)) {
          return true;
        }
      }

      const eventData = {
        name: eventName,
        data,
        timestamp: now
      };

      // Update the last event reference
      lastEventRef.current.set(eventName, {
        timestamp: now,
        data
      });

      metricsRef.current.events.push(eventData);
      
      // Log event for development
      if (process.env.NODE_ENV === 'development') {
        console.log('Event tracked:', eventData);
      }

      // Here you could add additional tracking integrations
      // For example: analytics.trackEvent(eventName, data);
      
      return true;
    } catch (error) {
      console.error('Error tracking event:', error);
      return false;
    }
  }, []);

  const trackError = useCallback((errorMessage, errorData = {}) => {
    try {
      const errorInfo = {
        message: errorMessage,
        data: errorData,
        timestamp: Date.now(),
        stack: new Error().stack
      };

      metricsRef.current.errors.push(errorInfo);
      
      // Log error for development
      if (process.env.NODE_ENV === 'development') {
        console.error('Error tracked:', errorInfo);
      }

      // Here you could add additional error reporting integrations
      // For example: errorReporting.captureError(errorInfo);
      
      return true;
    } catch (error) {
      console.error('Error tracking error:', error);
      return false;
    }
  }, []);

  const getMetrics = useCallback(() => {
    const calculateAverage = (arr) => {
      return arr.length > 0 
        ? arr.reduce((sum, item) => sum + item.duration, 0) / arr.length 
        : 0;
    };

    return {
      apiLatency: calculateAverage(metricsRef.current.apiCalls),
      validationTime: calculateAverage(metricsRef.current.validations),
      generationTime: calculateAverage(metricsRef.current.generations),
      totalApiCalls: metricsRef.current.apiCalls.length,
      totalValidations: metricsRef.current.validations.length,
      totalGenerations: metricsRef.current.generations.length,
      totalEvents: metricsRef.current.events.length,
      totalErrors: metricsRef.current.errors.length,
      recentEvents: metricsRef.current.events.slice(-10), // Last 10 events
      recentErrors: metricsRef.current.errors.slice(-10)  // Last 10 errors
    };
  }, []);

  const clearMetrics = useCallback(() => {
    metricsRef.current = {
      apiCalls: [],
      validations: [],
      generations: [],
      events: [],
      errors: []
    };
    operationTimersRef.current.clear();
    lastEventRef.current.clear();
  }, []);

  return {
    startOperation,
    endOperation,
    getMetrics,
    clearMetrics,
    trackEvent,
    trackError
  };
};

export default useMetrics;