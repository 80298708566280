// src/config.js
// src/config.js
export const DEVELOPMENT_MODE = true;
export const DEV_USER_EMAIL = 'jim.bressoud@gmail.com';

export const isDevMode = (email) => {
  return email === DEV_USER_EMAIL;
};

export const isLocalhost = () => {
  return window.location.hostname === 'localhost';
};