import React, { useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import { useAuth } from '../../contexts/auth/AuthContext';
import { useRoleBasedAccess } from '../../contexts/auth/roleUtils';
import EnhancedClaudeGenerator from '../../components/EnhancedClaudeGenerator/EnhancedClaudeGenerator';
import ScenarioForm from '../../components/ScenarioForm/ScenarioForm';
import CSVImport from '../../components/CSVImport/CSVImportForm';
import { Link } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import ProtocolsGrid from './ProtocolsGrid';
import './AdminPage.css';

// Set worker path for PDF.js
pdfjsLib.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker.entry');

const AdminPage = () => {
  const { user } = useAuth();
  const { canGenerateScenarios, canValidateScenarios, canImportCSV } = useRoleBasedAccess();
  
  // State management
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [showScenarioForm, setShowScenarioForm] = useState(false);

  // Helper function to extract keywords from text
  const extractKeywords = (text) => {
    const words = text.toLowerCase()
      .replace(/[^\w\s]/g, '')
      .split(/\s+/)
      .filter(word => word.length > 4)
      .filter((word, index, self) => self.indexOf(word) === index)
      .slice(0, 10)
      .map(word => `#${word}`);
    
    return words;
  };

  // Handle PDF upload and processing
  const handlePDFUpload = async (file) => {
    if (!file) return;
    
    try {
      setProcessing(true);
      setError(null);

      const title = window.prompt('Please enter a title for this protocol:', file.name);
      if (!title) {
        setProcessing(false);
        return;
      }

      const reader = new FileReader();
      
      const pdfContent = await new Promise((resolve, reject) => {
        reader.onload = async (e) => {
          try {
            const typedArray = new Uint8Array(e.target.result);
            const loadingTask = pdfjsLib.getDocument({ data: typedArray });
            const pdf = await loadingTask.promise;
            let fullText = '';
            
            for (let i = 1; i <= pdf.numPages; i++) {
              const page = await pdf.getPage(i);
              const textContent = await page.getTextContent();
              const pageText = textContent.items
                .map(item => item.str)
                .join(' ');
              fullText += `\n--- Page ${i} ---\n${pageText}\n`;
            }
            resolve(fullText);
          } catch (error) {
            reject(error);
          }
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });

      // Store in Supabase
      const { error: insertError } = await supabase
        .from('protocols')
        .insert({
          title,
          content: pdfContent,
          keywords: extractKeywords(pdfContent),
          user_id: user.id,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString()
        });

      if (insertError) throw insertError;

      alert('Protocol processed and stored successfully!');
    } catch (error) {
      console.error('PDF processing error:', error);
      setError(error.message);
    } finally {
      setProcessing(false);
    }
  };

  // Handle successful scenario generation
  const handleGenerationComplete = (scenarioId) => {
    console.log('Scenario generated successfully:', scenarioId);
  };

  // Handle generation errors
  const handleGenerationError = (error) => {
    console.error('Generation error:', error);
    setError(error.message);
  };

  return (
    <div className="admin-container">
      {/* Header Section */}
      <div className="admin-header">
        <h1>Admin Dashboard</h1>
        <Link to="/datasheet" className="btn btn-secondary">
          Back to Datasheet
        </Link>
      </div>

      <div className="admin-actions">
        {/* Scenario Generation Section */}
        {canGenerateScenarios && (
          <div className="action-card">
            <h2>Scenario Generation</h2>
            <div className="button-group">
              {/* Claude Generator */}
              <EnhancedClaudeGenerator 
                supabase={supabase}
                onGenerationComplete={handleGenerationComplete}
                onError={handleGenerationError}
                searchParams={{
                  difficulty: "medium",
                  category: "general",
                  numberOfQuestions: 5,
                  complexity: "moderate",
                  role: "EMT"
                }}
/>
              {/* Manual Scenario Creation */}
              <button 
                onClick={() => setShowScenarioForm(true)} 
                className="btn btn-primary"
              >
                Bulk Scenario Creation
              </button>
            </div>
          </div>
        )}

        {/* Import/Export Section */}
        {canImportCSV && (
          <div className="action-card">
            <h2>Import/Export</h2>
            <div className="button-group">
              <CSVImport supabase={supabase} onImport={() => {}} />
              <button 
                onClick={() => {}} 
                className="btn btn-primary"
                disabled={processing}
              >
                Export CSV
              </button>
            </div>
          </div>
        )}

        {/* Protocol Processing Section */}
        <div className="action-card">
          <h2>Protocol Processing</h2>
          <div className="button-group">
            <input
              type="file"
              accept=".pdf"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) handlePDFUpload(file);
              }}
              style={{ display: 'none' }}
              id="pdf-upload"
              disabled={processing}
            />
            <label 
              htmlFor="pdf-upload" 
              className={`btn btn-primary ${processing ? 'disabled' : ''}`}
            >
              {processing ? 'Processing...' : 'Parse PDF'}
            </label>
            {error && (
              <div className="error-message">
                {error}
              </div>
            )}
          </div>
        </div>

        {/* Validation Section */}
        {canValidateScenarios && (
          <div className="action-card">
            <h2>Validation</h2>
            <Link to="/validation" className="btn btn-primary">
              Validate Scenarios
            </Link>
          </div>
        )}
      </div>

      {/* Protocols Grid Section */}
      <div className="action-card" style={{ marginTop: '2rem' }}>
        <h2>Protocols</h2>
        <ProtocolsGrid supabase={supabase} />
      </div>

      {/* Scenario Form Modal */}
      {showScenarioForm && (
        <ScenarioForm 
          supabase={supabase}
          onClose={() => setShowScenarioForm(false)}
          onSubmit={async (formData) => {
            console.log('Form submitted:', formData);
            setShowScenarioForm(false);
          }}
        />
      )}
    </div>
  );
};

export default AdminPage;