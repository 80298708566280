import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './ScenarioForm.css';

const ScenarioForm = ({ supabase, onClose, onSubmit }) => {
  // State management
  const [protocols, setProtocols] = useState([]);
  const [protocolSearchQuery, setProtocolSearchQuery] = useState('');
  const [diagnosisCategories, setDiagnosisCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    number_of_scenarios: 1,
    scenario_protocols: [],
    initial_prompt: '',
    diagnosiscategory: '',
    questiondiffic: '1',
    complexity: 'Medium',
    userrole: 'Paramedic',
    context_details: ''
  });

  // Helper function for formatting text
  const formatText = (text) => {
    if (!text) return '';
    return text.trim().charAt(0).toUpperCase() + text.trim().slice(1);
  };

  // Fetch initial data
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Fetch protocols
        const { data: protocolData, error: protocolError } = await supabase
          .from('protocols')
          .select('*');

        if (protocolError) throw new Error('Failed to fetch protocols');

        // Fetch diagnosis categories
        const { data: diagnosisData, error: diagnosisError } = await supabase
          .from('scenarios')
          .select('diagnosiscategory')
          .not('diagnosiscategory', 'is', null);

        if (diagnosisError) throw new Error('Failed to fetch diagnosis categories');

        // Process and set data
        setProtocols(protocolData || []);
        const uniqueDiagnoses = [...new Set(diagnosisData
          ?.map(d => formatText(d.diagnosiscategory))
          .filter(Boolean))] || [];
        setDiagnosisCategories(uniqueDiagnoses);

      } catch (error) {
        console.error('Error fetching form data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [supabase]);

  // Filter protocols based on search query
  const filteredProtocols = protocols.filter(protocol =>
    protocol.title.toLowerCase().includes(protocolSearchQuery.toLowerCase()) ||
    protocol.tags?.some(tag => tag.toLowerCase().includes(protocolSearchQuery.toLowerCase()))
  );

  // Handle toggling protocols selection
  const handleProtocolToggle = (protocolId) => {
    setFormData(prev => ({
      ...prev,
      scenario_protocols: prev.scenario_protocols.includes(protocolId)
        ? prev.scenario_protocols.filter(id => id !== protocolId)
        : [...prev.scenario_protocols, protocolId]
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { error: submitError } = await supabase
        .from('claudescenarioscript')
        .insert([{
          number_of_scenarios: formData.number_of_scenarios,
          scenario_protocols: formData.scenario_protocols,
          initial_prompt: formData.initial_prompt,
          diagnosiscategory: formData.diagnosiscategory,
          questiondiffic: formData.questiondiffic,
          complexity: formData.complexity,
          userrole: formData.userrole,
          context_details: formData.context_details,
          created_at: new Date(),
          created_by: supabase.auth.user()?.id
        }]);

      if (submitError) throw submitError;

      await onSubmit(formData);
      onClose();
    } catch (error) {
      console.error('Error submitting form:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>Generate Bulk Scenarios</h2>
          <button onClick={onClose} className="modal-close">×</button>
        </div>

        <div className="modal-body">
          <form onSubmit={handleSubmit} className="form">
            {/* Number of Scenarios */}
            <div className="form-group">
              <label>
                Number of Scenarios
                <input
                  type="number"
                  min="1"
                  max="10"
                  value={formData.number_of_scenarios}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    number_of_scenarios: Math.max(1, Math.min(10, parseInt(e.target.value) || 1))
                  }))}
                  required
                  className="form-control"
                />
              </label>
            </div>

            {/* Protocol Selection */}
            <div className="form-group">
              <label>Protocol Selection</label>
              <input
                type="text"
                placeholder="Search protocols..."
                value={protocolSearchQuery}
                onChange={(e) => setProtocolSearchQuery(e.target.value)}
                className="form-control"
              />
              <div className="protocols-list">
                {filteredProtocols.map(protocol => (
                  <div key={protocol.id} className="protocol-item">
                    <input
                      type="checkbox"
                      id={`protocol-${protocol.id}`}
                      checked={formData.scenario_protocols.includes(protocol.id)}
                      onChange={() => handleProtocolToggle(protocol.id)}
                    />
                    <label htmlFor={`protocol-${protocol.id}`}>
                      {protocol.title}
                      {protocol.tags && (
                        <div className="protocol-tags">
                          {protocol.tags.map(tag => `#${tag}`).join(' ')}
                        </div>
                      )}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            {/* Initial Prompt */}
            <div className="form-group">
              <label>
                Initial Prompt
                <textarea
                  value={formData.initial_prompt}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    initial_prompt: e.target.value
                  }))}
                  placeholder="Enter initial prompts for scenario generation"
                  rows={3}
                  className="form-control"
                />
              </label>
            </div>

            {/* Diagnosis Category */}
            <div className="form-group">
              <label>
                Diagnosis Category
                <select
                  value={formData.diagnosiscategory}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    diagnosiscategory: e.target.value
                  }))}
                  required
                  className="form-control"
                >
                  <option value="">Select Diagnosis Category</option>
                  {diagnosisCategories.map(category => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </label>
            </div>

            {/* Question Difficulty */}
            <div className="form-group">
              <label>
                Difficulty Level
                <select
                  value={formData.questiondiffic}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    questiondiffic: e.target.value
                  }))}
                  required
                  className="form-control"
                >
                  <option value="1">1 - Basic</option>
                  <option value="2">2 - Intermediate</option>
                  <option value="3">3 - Advanced</option>
                </select>
              </label>
            </div>

            {/* Complexity */}
            <div className="form-group">
              <label>
                Complexity
                <select
                  value={formData.complexity}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    complexity: e.target.value
                  }))}
                  required
                  className="form-control"
                >
                  <option value="Simple">Simple</option>
                  <option value="Medium">Medium</option>
                  <option value="Complex">Complex</option>
                </select>
              </label>
            </div>

            {/* User Role */}
            <div className="form-group">
              <label>
                Target Role
                <select
                  value={formData.userrole}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    userrole: e.target.value
                  }))}
                  required
                  className="form-control"
                >
                  <option value="EMT">EMT</option>
                  <option value="EMTA">EMTA</option>
                  <option value="Paramedic">Paramedic</option>
                  <option value="Medical School">Medical School</option>
                </select>
              </label>
            </div>

            {/* Additional Context */}
            <div className="form-group">
              <label>
                Additional Context
                <textarea
                  value={formData.context_details}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    context_details: e.target.value
                  }))}
                  placeholder="Enter any additional requirements or details"
                  rows={3}
                  className="form-control"
                />
              </label>
            </div>

            {/* Error Display */}
            {error && (
              <div className="error-message">
                {error}
              </div>
            )}

            {/* Form Actions */}
            <div className="form-actions">
              <button
                type="button"
                onClick={onClose}
                className="btn btn-secondary"
                disabled={loading}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {loading ? 'Generating...' : 'Generate Scenarios'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

ScenarioForm.propTypes = {
  supabase: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ScenarioForm;