import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import './ProtocolsGrid.css';

const ProtocolsGrid = ({ supabase }) => {
  const [protocols, setProtocols] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchProtocols = useCallback(async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('protocols')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setProtocols(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [supabase]);

  useEffect(() => {
    fetchProtocols();
  }, [fetchProtocols]);

  const handleDelete = async (id) => {
    if (!window.confirm('Are you sure you want to delete this protocol?')) return;

    try {
      const { error } = await supabase
        .from('protocols')
        .delete()
        .eq('id', id);

      if (error) throw error;
      
      setProtocols(protocols.filter(protocol => protocol.id !== id));
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) {
    return <div className="protocols-grid-container">Loading protocols...</div>;
  }

  if (error) {
    return <div className="protocols-grid-container text-red-500">Error: {error}</div>;
  }

  return (
    <div className="protocols-grid-container">
      <table className="protocols-grid-table">
        <thead>
          <tr>
            <th>Edit</th>
            <th>Delete</th>
            <th>Status</th>
            <th>Title</th>
            <th>Keywords</th>
            <th>Content</th>
          </tr>
        </thead>
        <tbody>
          {protocols.map((protocol) => (
            <tr key={protocol.id}>
              <td>
                <Link
                  to={`/admin/protocols/edit/${protocol.id}`}
                  className="protocols-grid-button edit"
                >
                  Edit
                </Link>
              </td>
              <td>
                <button
                  onClick={() => handleDelete(protocol.id)}
                  className="protocols-grid-button delete"
                >
                  Delete
                </button>
              </td>
              <td>
                <span className={`protocols-grid-status ${protocol.active ? 'active' : 'inactive'}`}>
                  {protocol.active ? 'active' : 'inactive'}
                </span>
              </td>
              <td>
                <div className="protocols-grid-content">
                  {protocol.title}
                </div>
              </td>
              <td>
                <div className="protocols-grid-keywords">
                  {protocol.keywords?.map((keyword, index) => (
                    <span
                      key={index}
                      className="protocols-grid-keyword"
                    >
                      {keyword}
                    </span>
                  ))}
                </div>
              </td>
              <td>
                <div className="protocols-grid-content">
                  {protocol.content}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProtocolsGrid;