// Helper function for formatting
export const toAlternatingCase = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const INITIAL_CONFIG = {
  scenario_protocols: [],
  initial_prompt: '',
  scenario_topic: '',
  category: '',
  diagnosiscategory: '',
  questiondiffic: '5',  // Changed to start at middle of 1-10 scale
  complexity: 'Moderate',
  userrole: 'Paramedic',
  context_details: '',
  protocolSearchQuery: ''
};

// Updated to 1-10 scale for difficulty
export const DIFFICULTY_LEVELS = Array.from({ length: 10 }, (_, i) => ({
  value: String(i + 1),
  label: `${i + 1}`
}));

export const COMPLEXITY_LEVELS = [
  { value: 'Easy', label: 'Easy' },
  { value: 'Moderate', label: 'Moderate' },
  { value: 'Complex', label: 'Complex' }
];

export const USER_ROLES = [
  { value: 'EMT', label: 'EMT' },
  { value: 'AEMT', label: 'AEMT' },
  { value: 'Paramedic', label: 'Paramedic' },
  { value: 'Medical Student', label: 'Medical Student' }
];

export const ERROR_MESSAGES = {
  NO_AUTH: 'No authenticated user found',
  INIT_ERROR: 'Failed to initialize',
  PROTOCOL_FETCH_ERROR: 'Failed to fetch protocols',
  CATEGORY_FETCH_ERROR: 'Failed to fetch categories',
  SUBMISSION_ERROR: 'Failed to submit form',
  VALIDATION_ERROR: 'Please fill in all required fields'
};

export const GENERATION_EVENTS = {
  PROTOCOLS_LOADED: 'protocols_loaded',
  CATEGORIES_LOADED: 'categories_loaded',
  GENERATION_STARTED: 'generation_started',
  GENERATION_COMPLETED: 'generation_completed',
  GENERATION_ERROR: 'generation_error'
};

export const FORM_EVENTS = {
  FORM_SUBMITTED: 'form_submitted',
  FORM_CANCELLED: 'form_cancelled',
  FIELD_CHANGED: 'field_changed',
  PROTOCOLS_SELECTED: 'protocols_selected',
  CATEGORIES_LOADED: 'categories_loaded'
};

// API and Generation Constants
export const BACKOFF_BASE = 2;
export const GENERATION_TIMEOUT = 30000;
export const MAX_RETRY_ATTEMPTS = 3;

// Formatting Constants
export const DATE_FORMAT = 'MM/DD/YYYY';
export const TIME_FORMAT = 'HH:mm:ss';
export const DATETIME_FORMAT = 'MM/DD/YYYY HH:mm:ss';

// Text Formatting
export const formatTitle = (text) => {
  return text.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
};

export const formatCategory = (category) => {
  return category ? toAlternatingCase(category) : '';
};

export const formatDiagnosis = (diagnosis) => {
  return diagnosis ? toAlternatingCase(diagnosis) : '';
};

// Validation Helpers
export const isValidEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

export const sanitizeText = (text) => {
  return text.trim().replace(/[<>]/g, '');
};