import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './GenerationForm.css';
import { Alert, AlertDescription } from './ui/Alert';
import { 
  DIFFICULTY_LEVELS,
  COMPLEXITY_LEVELS,
  USER_ROLES,
  ERROR_MESSAGES,
  formatCategory,
  formatDiagnosis 
} from '../utils/constants';

export const GenerationForm = ({
  supabase,
  onSubmit,
  onCancel,
  isGenerating = false,
  user
}) => {
  const [formData, setFormData] = useState({
    protocols: [],
    protocolSearchQuery: '',
    initial_prompt: '',
    scenario_topic: '',
    category: '',
    diagnosisCategory: '',
    difficulty: '5',
    complexity: COMPLEXITY_LEVELS[0].value,
    role: 'Paramedic',
    context_details: ''
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [protocols, setProtocols] = useState([]);
  const [categories, setCategories] = useState([]);
  const [diagnosisCategories, setDiagnosisCategories] = useState([]);

  // Fetch form data
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Fetch protocols
        const { data: protocolData, error: protocolError } = await supabase
        .from('protocols')
        .select('*')  

        // Fetch categories
        const { data: categoryData, error: categoryError } = await supabase
          .from('scenarios')
          .select('category')
          .not('category', 'is', null);

        if (categoryError) throw new Error('Failed to fetch categories');

        // Fetch diagnosis categories
        const { data: diagnosisData, error: diagnosisError } = await supabase
          .from('scenarios')
          .select('diagnosiscategory')
          .not('diagnosiscategory', 'is', null);

        if (diagnosisError) throw new Error('Failed to fetch diagnosis categories');

        // Process and set data
        setProtocols(protocolData || []);
        setCategories([...new Set(categoryData?.map(c => formatCategory(c.category)).filter(Boolean))] || []);
        setDiagnosisCategories([...new Set(diagnosisData?.map(d => formatDiagnosis(d.diagnosiscategory)).filter(Boolean))] || []);

      } catch (error) {
        console.error('Error fetching form data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [supabase]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Prepare submission data
      const submissionData = {
        ...formData,
        created_at: new Date().toISOString(),
        created_by: user?.email
      };

      await onSubmit(submissionData);
    } catch (error) {
      console.error('Form submission error:', error);
      setError(ERROR_MESSAGES.SUBMISSION_ERROR);
    }
  };

  if (loading) {
    return <div className="p-4 text-center">Loading form data...</div>;
  }

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Protocols Section */}
      <div className="form-section">
        <div className="form-section-title">Select Reference Protocols</div>
        <div className="form-section-subtitle">Choose protocols to guide scenario generation</div>
        
        <input
          type="text"
          placeholder="Search protocols..."
          value={formData.protocolSearchQuery}
          onChange={(e) => setFormData({ 
            ...formData, 
            protocolSearchQuery: e.target.value 
          })}
          className="form-input protocols-search"
        />

        <div className="protocols-list">
          {protocols
            .filter(protocol => 
              !formData.protocolSearchQuery || 
              protocol.title.toLowerCase().includes(formData.protocolSearchQuery.toLowerCase())
            )
            .map(protocol => (
              <div key={protocol.id} className="protocol-item">
                <input
                  type="checkbox"
                  id={`protocol-${protocol.id}`}
                  checked={formData.protocols.includes(protocol.id)}
                  onChange={(e) => {
                    const newProtocols = e.target.checked
                      ? [...formData.protocols, protocol.id]
                      : formData.protocols.filter(id => id !== protocol.id);
                    setFormData({ ...formData, protocols: newProtocols });
                  }}
                  className="protocol-checkbox"
                />
                <label htmlFor={`protocol-${protocol.id}`}>
                  <div className="protocol-title">{protocol.title}</div>
                  {protocol.tags && (
                    <div className="protocol-tags">
                      {protocol.tags.map(tag => `##${tag}`).join(', ')}
                    </div>
                  )}
                </label>
              </div>
            ))}
        </div>
      </div>

      {/* Initial Prompts */}
      <div className="form-section">
        <label className="form-label">
          Initial Prompts
          <textarea
            value={formData.initial_prompt}
            onChange={(e) => setFormData({ ...formData, initial_prompt: e.target.value })}
            className="form-textarea"
            placeholder="Enter any initial prompts..."
            rows={4}
          />
        </label>
      </div>

      {/* Scenario Topic */}
      <div>
        <label className="block font-medium mb-2">Scenario Topic</label>
        <input
          type="text"
          value={formData.scenario_topic}
          onChange={(e) => setFormData({ ...formData, scenario_topic: e.target.value })}
          className="w-full p-2 border rounded"
          required
          placeholder="Enter scenario topic"
        />
      </div>

      {/* Category */}
      <div>
        <label className="block font-medium mb-2">Category</label>
        <select
          value={formData.category}
          onChange={(e) => setFormData({ ...formData, category: e.target.value })}
          className="w-full p-2 border rounded"
          required
        >
          <option value="">Select Category</option>
          {categories.map(category => (
            <option key={category} value={category}>{category}</option>
          ))}
        </select>
      </div>

      {/* Diagnosis Category */}
      <div>
        <label className="block font-medium mb-2">Diagnosis Category</label>
        <select
          value={formData.diagnosisCategory}
          onChange={(e) => setFormData({ ...formData, diagnosisCategory: e.target.value })}
          className="w-full p-2 border rounded"
          required
        >
          <option value="">Select Diagnosis Category</option>
          {diagnosisCategories.map(category => (
            <option key={category} value={category}>{category}</option>
          ))}
        </select>
      </div>

      {/* Difficulty */}
      <div>
        <label className="block font-medium mb-2">Difficulty (1-10)</label>
        <input
          type="number"
          min="1"
          max="10"
          value={formData.difficulty}
          onChange={(e) => setFormData({ ...formData, difficulty: e.target.value })}
          className="w-full p-2 border rounded"
          required
        />
      </div>

      {/* Complexity */}
      <div>
        <label className="block font-medium mb-2">Complexity</label>
        <select
          value={formData.complexity}
          onChange={(e) => setFormData({ ...formData, complexity: e.target.value })}
          className="w-full p-2 border rounded"
          required
        >
          {COMPLEXITY_LEVELS.map(level => (
            <option key={level.value} value={level.value}>{level.label}</option>
          ))}
        </select>
      </div>

      {/* Role */}
      <div>
        <label className="block font-medium mb-2">Role</label>
        <select
          value={formData.role}
          onChange={(e) => setFormData({ ...formData, role: e.target.value })}
          className="w-full p-2 border rounded"
          required
        >
          {USER_ROLES.map(role => (
            <option key={role.value} value={role.value}>{role.label}</option>
          ))}
        </select>
      </div>

      {/* Additional Context */}
      <div>
        <label className="block font-medium mb-2">Additional Context</label>
        <textarea
          value={formData.context_details}
          onChange={(e) => setFormData({ ...formData, context_details: e.target.value })}
          className="w-full p-2 border rounded"
          rows={3}
          placeholder="Enter any additional context..."
        />
      </div>

      {/* Form Actions */}
      <div className="form-actions">
        <button
          type="button"
          onClick={onCancel}
          className="btn btn-cancel"
          disabled={isGenerating}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="btn btn-submit"
          disabled={isGenerating}
        >
          {isGenerating ? 'Generating...' : 'Generate Scenario'}
        </button>
      </div>
    </form>
  );
};

GenerationForm.propTypes = {
  supabase: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isGenerating: PropTypes.bool,
  user: PropTypes.object
};

export default GenerationForm;