import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://gfmtnfxmtlkzzhaebrvk.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImdmbXRuZnhtdGxrenpoYWVicnZrIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjEzODk3MzksImV4cCI6MjAzNjk2NTczOX0.zMt6y03HWSFRr01t1YRYuQkD14Rp-prfIch-yBixr60';

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
    storage: localStorage
  },
  headers: {
    'apikey': supabaseAnonKey
  }
});