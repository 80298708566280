import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth/AuthContext';
import { useScenarioGeneration } from './hooks/useScenarioGeneration';
import { useMetrics } from './hooks/useMetrics';
import { useProgress } from './hooks/useProgress';
import { GenerationForm } from './components/GenerationForm';
import { ErrorAlert } from './components/ErrorAlert';
import { GenerationProgress } from './components/GenerationProgress';
import { validateScenarioConfig } from './utils/validation';
import { trackEvent, logError } from './utils/analytics';
import { 
  INITIAL_CONFIG,
  ERROR_MESSAGES,
  GENERATION_EVENTS 
} from './utils/constants';
import './EnhancedClaudeGenerator.css';

// Helper function for formatting
const toAlternatingCase = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const EnhancedClaudeGenerator = ({
  supabase,
  onGenerationComplete,
  onError,
  scenarioConfig: initialScenarioConfig
}) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { trackGeneration, trackError } = useMetrics();
  const { progress, startProgress, updateProgress, resetProgress } = useProgress();

  // State management
  const [showDialog, setShowDialog] = useState(false);
  const [protocols, setProtocols] = useState([]);
  const [diagnosisCategories, setDiagnosisCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [scenarioConfig, setScenarioConfig] = useState(initialScenarioConfig || INITIAL_CONFIG);

  // Fetch protocols with useCallback
  const fetchProtocols = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('protocols')
        .select('*')
        .eq('active', true)
        .order('title');
      
      if (error) throw error;
      setProtocols(data || []);
      trackEvent(GENERATION_EVENTS.PROTOCOLS_LOADED);
    } catch (error) {
      logError('Error fetching protocols:', error);
      setError({ message: ERROR_MESSAGES.PROTOCOL_FETCH_ERROR });
    }
  }, [supabase, trackEvent]);

  // Fetch diagnosis categories with useCallback
  const fetchDiagnosisCategories = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('scenarios')
        .select('diagnosiscategory')
        .not('diagnosiscategory', 'is', null);
      
      if (error) throw error;

      const uniqueDiagnosisCategories = [...new Set(data.map(item => item.diagnosiscategory))]
        .filter(Boolean)
        .map(category => toAlternatingCase(category))
        .sort((a, b) => a.localeCompare(b));

      setDiagnosisCategories(uniqueDiagnosisCategories);
      trackEvent(GENERATION_EVENTS.CATEGORIES_LOADED);
    } catch (error) {
      logError('Error fetching diagnosis categories:', error);
      setError({ message: ERROR_MESSAGES.CATEGORY_FETCH_ERROR });
    }
  }, [supabase, trackEvent]);

  // Scenario Generation Hook
  const {
    isGenerating,
    generationError,
    generationProgress,
    startGeneration,
    handleCancel
  } = useScenarioGeneration({
    supabase,
    onComplete: (scenarioId) => {
      trackGeneration(scenarioId);
      if (onGenerationComplete) {
        onGenerationComplete(scenarioId);
      }
      setShowDialog(false);
      resetProgress();
      navigate(`/scenarios/${scenarioId}`);
    },
    onError: (error) => {
      trackError(error);
      setError(error);
      if (onError) onError(error);
    }
  });

  // Initialize data and check auth
  useEffect(() => {
    const initializeData = async () => {
      try {
        if (!user) {
          setError({ message: ERROR_MESSAGES.NO_AUTH });
          navigate('/login');
          return;
        }

        setLoading(true);
        await Promise.all([
          fetchProtocols(),
          fetchDiagnosisCategories()
        ]);
        
      } catch (err) {
        logError('Initialization failed:', err);
        setError({ message: ERROR_MESSAGES.INIT_ERROR });
        navigate('/login');
      } finally {
        setLoading(false);
      }
    };

    initializeData();
  }, [navigate, user, fetchProtocols, fetchDiagnosisCategories]);

  // Form Submission Handler
  const handleSubmit = async (formData) => {
    try {
      // Validate form data
      const validationErrors = validateScenarioConfig(formData);
      if (validationErrors) {
        setError({ message: validationErrors });
        return;
      }

      startProgress();
      
      // Save search criteria to claudescenarioscript table
      const { error: insertError } = await supabase
        .from('claudescenarioscript')
        .insert([{
          scenario_topic: formData.scenario_topic,
          difficulty: formData.questiondiffic,
          category: formData.category,
          complexity: formData.complexity,
          role: formData.userrole,
          created_at: new Date().toISOString(),
          created_by: user.email,
          initial_prompt: formData.initial_prompt,
          context_details: formData.context_details,
          scenario_protocols: formData.scenario_protocols,
          diagnosiscategory: formData.diagnosiscategory
        }]);

      if (insertError) throw insertError;

      trackEvent(GENERATION_EVENTS.GENERATION_STARTED);
      await startGeneration(formData);

    } catch (error) {
      logError('Error submitting form:', error);
      trackError(error);
      setError({ message: ERROR_MESSAGES.SUBMISSION_ERROR });
      if (onError) onError(error);
    }
  };

  // Handle dialog close
  const handleDialogClose = () => {
    if (isGenerating) {
      handleCancel();
    }
    setShowDialog(false);
    resetProgress();
    setError(null);
  };

  if (loading) {
    return <div className="loading-spinner">Loading...</div>;
  }

  return (
    <>
      <button 
        onClick={() => setShowDialog(true)}
        className="btn btn-primary generate-button"
        disabled={isGenerating}
      >
        Generate Scenario
      </button>

      {showDialog && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Generate Scenarios</h2>
              <button 
                onClick={handleDialogClose}
                className="modal-close"
                aria-label="Close"
              >
                ×
              </button>
            </div>

            <div className="modal-body">
              {(error || generationError) && (
                <ErrorAlert error={error || generationError} />
              )}

              {!isGenerating ? (
                <GenerationForm
                  supabase={supabase}
                  onSubmit={handleSubmit}
                  onCancel={handleDialogClose}
                  isGenerating={isGenerating}
                  config={scenarioConfig}
                  onChange={setScenarioConfig}
                  protocols={protocols}
                  diagnosisCategories={diagnosisCategories}
                  user={user}
                />
              ) : (
                <GenerationProgress
                  progress={generationProgress}
                  onCancel={() => {
                    const shouldCancel = window.confirm(
                      'Are you sure you want to cancel the scenario generation?'
                    );
                    if (shouldCancel) {
                      handleCancel();
                      setShowDialog(false);
                      resetProgress();
                    }
                  }}
                  status={progress.status}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

EnhancedClaudeGenerator.propTypes = {
  supabase: PropTypes.object.isRequired,
  onGenerationComplete: PropTypes.func,
  onError: PropTypes.func,
  scenarioConfig: PropTypes.object
};

export default EnhancedClaudeGenerator;