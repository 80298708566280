import React from 'react';
import PropTypes from 'prop-types';

const Alert = ({ children, variant = "default", ...props }) => {
  const variantClasses = {
    default: "bg-gray-100 border-gray-200",
    destructive: "bg-red-100 border-red-200 text-red-900"
  };

  return (
    <div
      role="alert"
      className={`border p-4 rounded-lg mb-4 ${variantClasses[variant]}`}
      {...props}
    >
      {children}
    </div>
  );
};

const AlertTitle = ({ children, ...props }) => {
  return (
    <h5 className="font-medium mb-2" {...props}>
      {children}
    </h5>
  );
};

const AlertDescription = ({ children, ...props }) => {
  return (
    <div className="text-sm" {...props}>
      {children}
    </div>
  );
};

Alert.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(['default', 'destructive'])
};

AlertTitle.propTypes = {
  children: PropTypes.node
};

AlertDescription.propTypes = {
  children: PropTypes.node
};

// Export all components
export { Alert, AlertTitle, AlertDescription };