import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { useAuth } from '../../contexts/auth/AuthContext';
import './LoginScreen.css';

function LoginScreen() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      console.log('Attempting login with email:', email);
      
      const { data, error } = await supabase
        .from('user_additional_attributes')
        .select(`
          email, 
          fullname, 
          password, 
          solved_role,
          require_password_change,
          reviewer,
          administrator,
          configuration_manager,
          validator,
          scenario_generator,
          approver
        `);

      if (error) {
        console.error('Database query error:', error);
        throw error;
      }

      const foundUser = data.find(u => u.email.toLowerCase() === email.toLowerCase().trim());
      console.log('Found user:', foundUser);

      if (!foundUser) {
        throw new Error('Email not found');
      }

      if (foundUser.password !== password) {
        throw new Error('Invalid password');
      }

      const roles = {
        reviewer: foundUser.reviewer || false,
        administrator: foundUser.administrator || false,
        configuration_manager: foundUser.configuration_manager || false,
        validator: foundUser.validator || false,
        scenario_generator: foundUser.scenario_generator || false,
        approver: foundUser.approver || false
      };

      const hasValidRole = Object.values(roles).some(role => role === true);
      if (!hasValidRole) {
        throw new Error('Access denied: User does not have required roles');
      }

      const userData = {
        email: foundUser.email,
        fullname: foundUser.fullname || foundUser.email,
        role: foundUser.solved_role || null,
        requirePasswordChange: foundUser.require_password_change || false,
        roles: roles
      };

      console.log('userData being passed to login:', JSON.stringify(userData, null, 2));

      try {
        const loginResult = await login(userData);
        console.log('Login function result:', loginResult);
      } catch (loginError) {
        console.error('Login function error:', loginError);
        throw loginError;
      }

      if (userData.requirePasswordChange) {
        console.log('Navigating to password change');
        navigate('/change-password');
      } else {
        console.log('Navigating to home');
        navigate('/home');
      }
      
    } catch (error) {
      console.error('Login error details:', error);
      setError(error.message || 'Login failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-screen">
      <div className="login-container">
        <h1>EMTeams Scenario Review</h1>
        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
              className="email-input"
              disabled={loading}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              required
              className="password-input"
              disabled={loading}
            />
          </div>
          <button type="submit" className="login-button" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </button>
          {error && <p className="error-message">{error}</p>}
        </form>
      </div>
    </div>
  );
}

export default LoginScreen;